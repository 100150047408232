import React from 'react';
import { useTheme } from '@mui/material/styles';
import { LinearProgress } from '@mui/material';
import { ReactComponent as ArrowIcon } from '../assets/arrow.svg';
import Chip from './Chip'; // Import the reusable Chip component

const ProgressBar = ({ label, percentage, count, barColor }) => {
  const theme = useTheme();

  return (
    <div className="grid md:grid-cols-6 xxs:grid-cols-1 xxs:gap-x-0 gap-y-4 items-center mb-4">
      {/* Label */}
      <span className="text-[var(--color-text1)] text-start medium-text ml-4">{label}</span>

      {/* Progress Bar */}
      <div className="col-span-3">
        <LinearProgress
          variant="determinate"
          value={percentage}
          sx={{
            height: '14px',
            borderRadius: '12px',
            backgroundColor: theme.palette.mode === "light" ? '#D9D9D9': '#2A2D3E', // Background for unfilled part
            '& .MuiLinearProgress-bar': {
              backgroundColor: barColor, // Customizable bar color
              borderRadius: '12px',
            },
          }}
        />
      </div>

      {/* Percentage and Count with Chip */}
      <div className="col-span-2 text-[var(--color-text1)] flex items-center justify-start ml-4">
        {/* Percentage Chip */}
        <Chip label={`${percentage}%`} barColor={barColor} />
        <ArrowIcon />
        {/* Count Chip */}
        <Chip label={count} />
      </div>
    </div>
  );
};

export default ProgressBar;
