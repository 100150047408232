import React, { useState, useEffect, useMemo, useContext } from "react";
import TextInput from "../../shared/TextInput";
import Dropdown from "../../shared/DropDown";
import PrimaryButton from "../../shared/PrimaryButton";
import CancelButton from "../../shared/CancelButton";
import { ReactComponent as UserFocusIcon } from "../../assets/UserFocus.svg";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useUser } from "../../contexts/UserContext";
import { UserContext } from "../../contexts/UserContext";
import { updateUserProfile } from "../../services/userService";
import { getUser } from "../../services/userService";
import dayjs from "dayjs";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";
import femaleImg from "../../assets/female-avatar.svg";
import maleImg from "../../assets/male-avatar.svg";

function PersonalDetails() {
  const { user } = useUser();
  const { setUser } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  const splitName = (name) => {
    if (!name) return { firstName: "", lastName: "" };
    const parts = name.trim().split(" ");
    if (parts.length === 2) {
      return { firstName: parts[0], lastName: parts[1] };
    } else if (parts.length > 2) {
      return {
        firstName: parts[0],
        lastName: parts.slice(1).join(" "),
      };
    } else {
      return { firstName: parts[0] || "", lastName: "" };
    }
  };
  const [userDetails, setUserDetails] = useState({
    ...splitName(user?.name),
    gender: user?.gender,
    dateOfBirth: user?.dob ? dayjs(user.dob) : null,
    email: user?.email,
    contactNumber: user?.contact_no,
    labName: user?.lab_name,
    labLocation: user?.lab_location,
    labId: user?.lab_id,
    profilePhoto: user?.profile_photo,
    timings: user?.timings ? JSON.parse(user.timings.replace(/'/g, '"')) : [],
    emailID: user?.lab_email_id,
    phoneNumber: user?.lab_contact_no,
  });

  const genderOptions = useMemo(
    () => [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ],
    []
  );

  const [selectedGender, setSelectedGender] = useState(
    user?.gender
      ? genderOptions.find((option) => option.value === user.gender)
      : null
  );

  const [imagePreview, setImagePreview] = useState(user?.profile_photo || null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleInputChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  // Allowed file types and max file size (800kB)
  const allowedFileTypes = ["image/jpeg", "image/png", "image/gif"];
  const maxFileSize = 800 * 1024; // 800kB in bytes

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Reset error state
    setErrorMessage("");

    if (file) {
      // Validate file type
      if (!allowedFileTypes.includes(file.type)) {
        setErrorMessage("Only JPG, PNG, or GIF files are allowed.");
        return;
      }

      // Validate file size
      if (file.size > maxFileSize) {
        setErrorMessage("File size must not exceed 800kB.");
        return;
      }

      // Preview the uploaded image
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
    setUserDetails((prevState) => ({ ...prevState, gender: gender.value }));
  };

  const handleTimingsChange = (newTimings) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      timings: newTimings,
    }));
  };

  const handleEditProfile = async () => {
    try {
      // Join firstName and lastName before updating the user
      const fullName = `${userDetails.firstName} ${userDetails.lastName}`;

      const formattedDOB = userDetails.dateOfBirth
        ? userDetails.dateOfBirth.format("DD/MM/YYYY")
        : null;

      const updatedUserDetails = {
        ...userDetails,
        name: fullName,
        dob: formattedDOB,
        contact_no: userDetails.contactNumber,
        lab_location: userDetails.labLocation,
        lab_name: userDetails.labName,
        lab_id: userDetails.labId,
        timings: userDetails.timings,
        profile_photo: imagePreview,
        lab_email_id: userDetails.emailID,
        lab_contact_no: userDetails.phoneNumber,
      };

      // Send the updated userDetails to the API
      await updateUserProfile(updatedUserDetails);
      const user = await getUser();
      setUser(user);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  useEffect(() => {
    if (user) {
      setSelectedGender(
        genderOptions.find((option) => option.value === user.gender) || null
      );
      setUserDetails({
        ...splitName(user.name),
        gender: user.gender,
        dateOfBirth: user.dob ? dayjs(user.dob) : null,
        email: user.email,
        contactNumber: user.contact_no,
        labName: user.lab_name,
        labLocation: user.lab_location,
        labId: user.lab_id,
        timings: user.timings
          ? JSON.parse(user.timings.replace(/'/g, '"'))
          : [],
        emailID: user.lab_email_id,
        phoneNumber: user.lab_contact_no,
      });
    }
  }, [user, genderOptions]);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const isFormValid = () => {
    return (
      validateEmail(userDetails.email) &&
      validatePhoneNumber(userDetails.contactNumber)
    );
  };

  return (
    <div>
      <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Personal Information
      </div>

      <div
        className="m-6 bg-[var(--color-white)] py-6 px-4 rounded-[10px]"
        style={{ border: "1px solid #CFD8DC" }}
      >
        <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 items-center">
          <img
            src={
              imagePreview
                ? imagePreview // If the user has an avatar, use it
                : userDetails.gender === "Female" // If gender is female, use a default female avatar
                ? femaleImg
                : maleImg // Otherwise, use a default male avatar
            }
            alt="User"
            className="w-[150px] h-[150px] object-cover object-center rounded-[24px]"
          />
          <div className="flex flex-col md:ml-6 space-y-4">
            <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 md:space-x-4">
              <input
                type="file"
                accept="image/jpeg, image/png, image/gif"
                id="file-input"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <PrimaryButton
                type="submit"
                label="Upload Image"
                leftIcon={<UserFocusIcon />}
                className="w-30 mt-4"
                onClick={() => document.getElementById("file-input").click()}
              />

              <CancelButton
                type="submit"
                label="Reset"
                leftIcon={<LoopOutlinedIcon sx={{ color: "#7786A3" }} />}
                className="md:w-40"
                onClick={() => {
                  setErrorMessage(""); // Clear errors
                }}
              />
            </div>
            <p className="md:ml-6 text-[var(--color-dark-grey)]">
              Allowed JPG, GIF, or PNG file size of 800k,
            </p>
            {errorMessage && (
              <p className="text-error text-sm mt-1">{errorMessage}</p>
            )}
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-16">
          <TextInput
            id="first-name"
            label="First Name"
            value={userDetails.firstName || ""}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
          />

          <TextInput
            id="last-name"
            label="Last Name"
            value={userDetails.lastName || ""}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
          />

          <div>
            <Dropdown
              id="gender"
              label="Gender"
              showLabel={true}
              options={genderOptions}
              selectedOption={selectedGender}
              onChange={handleGenderChange}
            />
          </div>

          <div className="w-full">
            <p className="text-sm mb-1 text-[var(--color-dark-grey)]">
              DOB (DD/MM/YY)
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={userDetails.dateOfBirth || null}
                onChange={(newValue) => {
                  handleInputChange(
                    "dateOfBirth",
                    newValue ? dayjs(newValue) : null
                  );
                  setOpen(false);
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    placeholder: "Choose Date",
                    sx: {
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                      },
                    },
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setOpen(true)}>
                            <CalendarMonthOutlinedIcon sx={{ ml: 0 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <TextInput
            id="email"
            label="Email"
            value={userDetails.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
            error={!validateEmail(userDetails.email)}
            helperText={
              validateEmail(userDetails.email) ? "" : "Email is required"
            }
          />

          <TextInput
            id="contact-number"
            label="Contact Number"
            type="number"
            value={userDetails.contactNumber || ""}
            onChange={(e) => handleInputChange("contactNumber", e.target.value)}
            error={!validatePhoneNumber(userDetails.contactNumber)}
            helperText={
              validatePhoneNumber(userDetails.contactNumber)
                ? ""
                : "Phone number must be 10 digits"
            }
          />
        </div>
      </div>

      <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Lab Details
      </div>

      <div
        className="m-6 bg-[var(--color-white)] py-6 px-4 rounded-[10px]"
        style={{ border: "1px solid #CFD8DC" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-16">
          <TextInput
            id="lab-name"
            label="Lab Name"
            value={userDetails.labName || ""}
            onChange={(e) => handleInputChange("labName", e.target.value)}
          />

          <TextInput
            id="lab-id"
            label="Lab Id"
            value={userDetails.labId || ""}
            onChange={(e) => handleInputChange("labId", e.target.value)}
          />

          <TextInput
            id="lab-location"
            label="Lab Location"
            value={userDetails.labLocation || ""}
            onChange={(e) => handleInputChange("labLocation", e.target.value)}
          />

          <div className="flex flex-col w-full">
            <span className="text-sm text-[var(--color-dark-grey)] mb-1">
              Timings
            </span>
            <TimeRangePicker
              value={userDetails.timings || []}
              onChange={handleTimingsChange}
            />
          </div>

          <TextInput
            id="email"
            label="Email ID"
            value={userDetails.emailID || ""}
            onChange={(e) => handleInputChange("emailID", e.target.value)}
            error={!validateEmail(userDetails.emailID)}
            helperText={
              validateEmail(userDetails.emailID) ? "" : "Email is required"
            }
          />

          <TextInput
            id="phone-number"
            label="Contact Number"
            type="number"
            value={userDetails.phoneNumber || ""}
            onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
            error={!validatePhoneNumber(userDetails.phoneNumber)}
            helperText={
              validatePhoneNumber(userDetails.phoneNumber)
                ? ""
                : "Phone number must be 10 digits"
            }
          />
        </div>
      </div>

      <div
        className="bottom-0 flex bg-[var(--color-white)]"
        style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
      >
        <div className="flex flex-row justify-end xxs:p-2 md:p-6 w-full">
          <PrimaryButton
            type="button"
            label="Save Changes"
            className="md:w-40"
            disabled={!isFormValid()}
            onClick={handleEditProfile}
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
