import React, { useState, useEffect } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  useMediaQuery,
} from "@mui/material";
import PrimaryButton from "../shared/PrimaryButton";
import CancelButton from "../shared/CancelButton";
import { styled } from "@mui/system";
import { CheckIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import ReportTypeStep from "../components/create-report/ReportTypeStep";
import UploadAudioStep from "../components/create-report/UploadAudioStep";
import TranscriptStep from "../components/create-report/TranscriptStep";
import SubmitStep from "../components/create-report/SubmitStep";
import CommonDialog from "../shared/CommonDialog";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { createReport } from "../services/reportService";

// Steps for the stepper
const steps = [
  {
    label: "Report Type",
    subtitle: "Report & Patient Details",
    path: "report-type",
  },
  { label: "Upload Audio", subtitle: "Add audio", path: "upload-audio" },
  { label: "Transcript", subtitle: "Your audio note", path: "transcript" },
  { label: "Submit", subtitle: "Preview & submit", path: "submit" },
];

// Custom connector to modify stepper's line color
const CustomConnector = styled(StepConnector)(({ theme }) => ({
  [`& .MuiStepConnector-line`]: {
    display: "none",
  },
}));

// Custom step icon styling
const CustomStepIcon = styled("div")(({ active, completed }) => ({
  width: "40px",
  height: "40px",
  borderRadius: "6px", // Change to rectangular
  backgroundColor: completed
    ? "#28C76F" // Color after completing
    : active
    ? "#FCAA5C" // Color for active step
    : "#B9B9B93D", // Default color
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: active ? "white" : "#A5A5A5",
  fontSize: "18px", // Font size for step number
}));

const CreateReportPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [showDraftIcon, setShowDraftIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(false);

  // Use media query to detect screen size
  const isSmallScreen = useMediaQuery("(max-width:640px)");
  const [formValid, setFormValid] = useState(false);

  const [reportDetails, setReportDetails] = useState({
    reportType: "",
    firstName: "",
    lastName: "",
    gender: "",
    dob: null,
    email: "",
    contactNumber: "",
    weight: "",
    height: "",
    template: "",
    templateContent: "",
    audioFile: null,
    audioName: "",
    transcriptText: "",
  });

  // Extract the step from the URL
  const currentStepPath = location.pathname.split("/").pop();
  const currentStepIndex = steps.findIndex(
    (step) => step.path === currentStepPath
  );
  const [activeStep, setActiveStep] = useState(
    currentStepIndex === -1 ? 0 : currentStepIndex
  );

  const onTranscribeComplete = (text) => {
    setReportDetails((prevState) => ({
      ...prevState,
      transcriptText: text,
    }));
  };

  // Initialize activeStep from URL on component mount
  useEffect(() => {
    if (currentStepIndex !== activeStep) {
      setActiveStep(currentStepIndex === -1 ? 0 : currentStepIndex);
    }
  }, [currentStepIndex, activeStep]);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
      navigate(`/create-report/${steps[activeStep + 1].path}`);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1);
      navigate(`/create-report/${steps[activeStep - 1].path}`);
    }
  };

  const handleOpenDialog1 = () => {
    setDialogTitle("Report Finalized Successfully!");
    setDialogContent("Report has been finalized successfully.");
    setButtonLabel("Okay");
    setDialogOpen(true);
    setShowDraftIcon(false);
    setShowRightIcon(true);
  };

  const handleOpenDialog2 = () => {
    setDialogTitle("Report Saved To Draft!");
    setDialogContent("Report has been saved as a draft successfully.");
    setButtonLabel("Okay");
    setDialogOpen(true);
    setShowDraftIcon(true);
    setShowRightIcon(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Add your confirm logic here
    navigate("/report-management");
  };

  const handleCreateReport = (status) => {
    try {
      const formattedDOB = reportDetails.dob
        ? reportDetails.dob.format("YYYY-MM-DD")
        : null;

      const reportData = {
        ...reportDetails,
        first_name: reportDetails.firstName,
        last_name: reportDetails.lastName,
        report_type: reportDetails.reportType.label,
        gender: reportDetails.gender.value,
        contact_number: reportDetails.contactNumber,
        dob: formattedDOB,
        transcribed_text: reportDetails.transcriptText,
        report_template: reportDetails.templateContent,
        status: status,
      };
      createReport(reportData);
    } catch (error) {
      console.error("Error creating report:", error);
    } finally {
      if (status === "Draft") {
        handleOpenDialog2();
      } else {
        handleOpenDialog1();
      }
    }
  };

  return (
    <div className="flex flex-col h-full">
      {/* Stepper and Content */}
      <div className="flex-1">
        <div
          className="flex flex-col items-start justify-start bg-[var(--color-white)] xxs:p-4 md:p-6 mt-4"
          style={{ boxShadow: "0px 2px 6px 0px #00000024" }}
        >
          {/* MUI Stepper */}
          <Stepper
            activeStep={activeStep}
            orientation={isSmallScreen ? "vertical" : "horizontal"} // Conditional orientation
            connector={<CustomConnector />}
            className="-ml-1 w-5/6"
          >
            {steps.map((step, index) => (
              <Step
                key={index}
                onClick={() => {
                  setActiveStep(index);
                  navigate(`/create-report/${step.path}`);
                }}
                className="cursor-pointer"
              >
                <StepLabel
                  StepIconComponent={(props) => (
                    <CustomStepIcon {...props} active={index === activeStep}>
                      {props.completed ? (
                        <CheckIcon
                          className="w-6 h-6 text-white"
                          strokeWidth={2}
                        />
                      ) : (
                        index + 1 // Show step number if not completed
                      )}
                    </CustomStepIcon>
                  )}
                >
                  {/* Displaying label and subtitle */}
                  <div className="flex flex-row justify-between items-center">
                    <div>
                      <p
                        className="text-base font-medium"
                        style={{
                          color:
                            index < activeStep
                              ? "#28C76F"
                              : index === activeStep
                              ? "#FCAA5C"
                              : "var(--color-text1)",
                        }}
                      >
                        {step.label}
                      </p>
                      <span className="text-xs text-blue-text">
                        {step.subtitle}
                      </span>
                    </div>
                    {index < steps.length - 1 && (
                      <ChevronRightIcon
                        className="ml-10 w-6 h-6 text-[var(--color-dark-grey)]"
                        strokeWidth={2}
                      />
                    )}
                  </div>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {/* Render the current step content */}
        <div className="w-full">
          <Routes>
            <Route
              path="report-type"
              element={
                <ReportTypeStep
                  reportDetails={reportDetails}
                  setReportDetails={setReportDetails}
                  setFormValid={setFormValid}
                />
              }
            />
            <Route
              path="upload-audio"
              element={
                <UploadAudioStep
                  reportDetails={reportDetails}
                  setReportDetails={setReportDetails}
                  onTranscribeComplete={onTranscribeComplete}
                  setFormValid={setFormValid}
                />
              }
            />
            <Route
              path="transcript"
              element={
                <TranscriptStep
                  reportDetails={reportDetails}
                  setReportDetails={setReportDetails}
                />
              }
            />
            <Route
              path="submit"
              element={<SubmitStep reportDetails={reportDetails} />}
            />
          </Routes>
        </div>
      </div>

      {/* Bottom Navigation Buttons */}
      <div
        className="md:sticky bottom-0 right-0 z-10 flex bg-[var(--color-white)]"
        style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
      >
        <div className="flex flex-row justify-between xxs:p-2 md:p-4 w-full">
          <div>
            {activeStep === 0 ? null : (
              <CancelButton
                type="button"
                label="Back"
                className="md:w-32"
                onClick={handleBack}
              />
            )}
          </div>
          <div className="flex flex-row space-x-4">
            {activeStep === steps.length - 1 && (
              <button
                className="bg-[var(--color-white)] border border-primary text-[var(--color-primary)] xxs:px-4 md:px-8 rounded-lg font-medium text-sm"
                onClick={() => handleCreateReport("Draft")}
              >
                Save as Draft
              </button>
            )}
            <PrimaryButton
              type="button"
              label={activeStep === steps.length - 1 ? "Finalize" : "Next"}
              disabled={!formValid}
              className="md:w-32"
              onClick={
                activeStep === steps.length - 1
                  ? () => handleCreateReport("Finalized")
                  : handleNext
              }
            />
          </div>
        </div>
      </div>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDraftIcon={showDraftIcon}
        showRightIcon={showRightIcon}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default CreateReportPage;
