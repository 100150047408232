import React, { useState } from "react";
import TextInput from "../../shared/TextInput";
import PrimaryButton from "../../shared/PrimaryButton";
import { ReactComponent as LockIcon } from "../../assets/flat-color-icons_lock.svg";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { forgotPassword } from "../../services/authService";

const ForgotPasswordForm = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState(""); // Input can be email or mobile number

  // Utility function to validate email
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Utility function to validate mobile number
  const isValidMobileNumber = (number) => {
    const mobileRegex = /^[6-9]\d{9}$/; // Matches Indian mobile numbers
    return mobileRegex.test(number);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    if (!isValidEmail(input) && !isValidMobileNumber(input)) {
      toast.error("Please enter a valid email address or mobile number.");
      return;
    }

    try {
      const response = await forgotPassword(input); // Call the API method
      console.log(response);
      if (response) {
        navigate("/verify-otp", {
          state: {
            identifier: input,
            fromForgotPassword: true, // Add state to verify-otp route
          },
        });
      }
    } catch (error) {}
  };

  return (
    <div
      className="bg-[var(--color-white)] rounded-[20px] padding-responsive"
      style={{ boxShadow: "0px 9px 34px 0px #0000001A" }}
    >
      <form onSubmit={handleForgotPassword} className="px-2">
        <div className="flex items-center mt-6">
          <span className="text-[var(--color-dark-grey)] mb-1 font-heading font-semibold large-text mr-2">
            Forgot Password?
          </span>
          <LockIcon className="-mt-2 xxs:w-8 xs:w-10" />
        </div>
        <div className="mb-8 text-blue-text small-text">
          Enter your email id and we’ll send you instructions to reset your
          password
        </div>
        <TextInput
          id="identifier"
          type="text"
          label="Email ID or Mobile Number"
          placeholder="Enter Email ID or Mobile Number"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          className="mb-10"
        />
        <PrimaryButton
          type="submit"
          label="Send OTP"
          className="w-full"
          onClick={handleForgotPassword}
        />
        <div className="mt-10 text-center medium-text flex items-center justify-center">
          <button
            type="button"
            className="text-blue text-sm flex items-center"
            onClick={() => navigate("/login")}
          >
            <ChevronLeftIcon
              className="h-4 w-4 text-blue mr-3"
              aria-hidden="true"
              strokeWidth={3}
            />
            Back to Login
          </button>
        </div>
        <div className="mt-10 mb-6 text-center medium-text">
          <span className="text-grey">Don't have an account?</span>{" "}
          <button
            type="button"
            className="font-medium"
            onClick={() => navigate("/register")}
          >
            Create Account
          </button>
        </div>
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
