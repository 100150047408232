import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Report from "../shared/Report";
import PrimaryButton from "../shared/PrimaryButton";
import CommonDialog from "../shared/CommonDialog";
import { ReactComponent as EditIcon } from "../assets/edit.svg";
import { ReactComponent as ShareIcon } from "../assets/share.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as TwitterIcon } from "../assets/twitter.svg";
import { ReactComponent as FacebookIcon } from "../assets/facebook.svg";
import { ReactComponent as GmailIcon } from "../assets/gmail.svg";
import { ReactComponent as WatsappIcon } from "../assets/watsapp.svg";
import { TextField, InputAdornment } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

function ReportDetailsPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [showDraftIcon, setShowDraftIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Sure You Want To Delete Account?");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Yes");
    setDialogOpen(true);
    setShowDeleteIcon(true);
    setShowDraftIcon(false);
    setShowRightIcon(false);
  };

  const handleOpenDialog1 = () => {
    setDialogTitle("Report Finalized Successfully!");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Okay");
    setDialogOpen(true);
    setShowDraftIcon(false);
    setShowRightIcon(true);
    setShowDeleteIcon(false);
  };

  const handleOpenDialog2 = () => {
    setDialogTitle("Report Saved To Draft!");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Okay");
    setDialogOpen(true);
    setShowDraftIcon(true);
    setShowRightIcon(false);
    setShowDeleteIcon(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Add your confirm logic here
    console.log("Saved successfully!");
  };
  
  return (
    <div className="flex flex-col h-full">
      <div className="flex xxs:flex-col sm:flex-row xxs:items-start xxs:space-y-4 sm:space-y-0 sm:items-center justify-between px-6 py-4 mt-4 bg-[var(--color-white)]">
        <span className="text-lg text-[var(--color-primary)]">Report</span>
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex bg-blue p-2 px-3 text-sm font-semibold text-white rounded-lg"
            onClick={() =>
              navigate("/edit-report/:reportId", {
                state: { fromSubmitStep: false },
              })
            }
          >
            <EditIcon className="mt-0.5 mr-2" />
            Edit
          </button>
          <button
            type="button"
            className="flex bg-green p-2 px-3 text-sm font-semibold text-white rounded-lg"
            onClick={handleClickOpen}
          >
            <ShareIcon className="mt-0.5 mr-2" />
            Share
          </button>
          <button
            type="button"
            className="flex bg-error p-2 px-3 text-sm font-semibold text-white rounded-lg"
            onClick={handleOpenDialog}
          >
            <DeleteIcon className="mt-0.5 mr-2 text-white" />
            Delete
          </button>
        </div>
      </div>
      <div className="p-6">
        <Report />
      </div>

      <div
        className="md:sticky bottom-0 right-0 z-10 flex bg-[var(--color-white)]"
        style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
      >
        <div className="flex flex-row justify-end xxs:p-2 md:p-6 space-x-4 w-full">
          <button
            className="bg-[var(--color-white)] border border-primary text-[var(--color-primary)] xxs:px-4 md:px-8 rounded-lg font-medium text-sm"
            onClick={handleOpenDialog2}
          >
            Save as Draft
          </button>
          <PrimaryButton
            type="button"
            label="Finalize"
            className="md:w-32"
            onClick={handleOpenDialog1}
          />
        </div>
      </div>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDraftIcon={showDraftIcon}
        showRightIcon={showRightIcon}
        showDeleteIcon={showDeleteIcon}
        onConfirm={handleConfirm}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            borderRadius: "10px",
            padding: "5px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, pt: 1, color: theme.palette.mode === "light"
              ? "#333333" : "#F3F3F3" }}
          id="customized-dialog-title"
        >
          Share this report
          <p className="text-sm text-[var(--color-dark-grey)]">Share this report</p>
        </DialogTitle>
        <button
          onClick={handleClose}
          type="button"
          className="absolute top-0 right-0 m-4 p-1 bg-grey2 rounded-full"
        >
          <XMarkIcon
            className="h-5 w-5"
            strokeWidth={2}
            style={{ color: "#4F4F4F" }}
          />
        </button>

        <DialogContent>
          <div className="flex w-full gap-x-10 items-center justify-center">
            <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#1DA1F21A" }}
              >
                <TwitterIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  strokeWidth={2}
                  style={{ color: "#1DA1F2" }}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#1DA1F2" }}>
                Twitter
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#1877F21A" }}
              >
                <FacebookIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  strokeWidth={2}
                  style={{ color: "#1877F2" }}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#1877F2" }}>
                Facebook
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#FF45001A" }}
              >
                <GmailIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  strokeWidth={2}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#FF4500" }}>
                G-mail
              </p>
            </div>
            <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#25D3661A" }}
              >
                <WatsappIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  strokeWidth={2}
                  style={{ color: "#25D366" }}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#25D366" }}>
                Whatsapp
              </p>
            </div>
          </div>
          <div className="mt-8 w-full">
            <TextField
              variant="outlined"
              placeholder="https://example.com/article/social-share-modal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ContentCopyIcon
                      sx={{
                        color: "#222222",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor: theme.palette.mode === "light" ? "white" : "transparent",
                  borderRadius: "8px",
                  height: "45px",
                  fontSize: "14px",
                },
              }}
              sx={{
                width: "100%",
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ReportDetailsPage;
