import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { ReactComponent as WavesIcon } from "../assets/waves.svg";
import { ReactComponent as DoctorIcon } from "../assets/doctor.svg";
import { ReactComponent as ReportsIcon } from "../assets/report-data.svg";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ReportsCard from "../shared/ReportsCard";

function LabDetailsPage() {
  const theme = useTheme();
  const location = useLocation();
  const lab = location.state?.lab;
  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#513ADC",
    },
  ]);

  const cardData = [
    {
      count: "45",
      label: "X-Ray",
      progressColor: "#FCAA5C",
      progressValue: 60,
      stats: { male: 35, female: 40, others: 35 },
    },
    {
      count: "38",
      label: "ECG",
      progressColor: "#7367F0",
      progressValue: 80,
      stats: { male: 18, female: 70, others: 25 },
    },
    {
      count: "65",
      label: "MRI",
      progressColor: "#28C76F",
      progressValue: 60,
      stats: { male: 18, female: 70, others: 25 },
    },
    {
      count: "63",
      label: "CT-Scan",
      progressColor: "#FF4C51",
      progressValue: 80,
      stats: { male: 18, female: 70, others: 25 },
    },
    {
      count: "59",
      label: "Sonography",
      progressColor: "#00BAD1",
      progressValue: 50,
      stats: { male: 18, female: 70, others: 25 },
    },
    {
      count: "72",
      label: "ECG",
      progressColor: "#8954A9",
      progressValue: 70,
      stats: { male: 18, female: 70, others: 25 },
    },
  ];

  return (
    <div className="m-6">
      <div className="flex xxs:flex-col md:flex-row xxs:space-x-0 md:space-x-6 md:space-y-0 xxs:space-y-6">
        <div
          className="bg-primary rounded-[10px] text-white p-5 xxs:w-full md:w-4/5"
          style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="md:text-[20px] xxs:text-sm font-semibold">
                {lab.name}
              </p>
              <span className="small-text font-medium">Monthly Overview</span>
            </div>
            <div className="bg-purple rounded-md px-4 py-1">
              <span className="font-medium medium-text">
                {lab.subscription} Subscription
              </span>
            </div>
          </div>
          <div className="mt-6 flex flex-row items-center justify-between">
            <div>
              <span className="text-sm font-medium">Reports</span>
              <div className="flex flex-col justify-between space-y-4 mt-2">
                <div className="flex space-x-12 font-medium">
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      244
                    </div>
                    <span className="medium-text ml-2">Finalized</span>
                  </div>
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      88
                    </div>
                    <span className="medium-text ml-2">Drafts</span>
                  </div>
                </div>
                <div className="flex space-x-14 font-medium">
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      10
                    </div>
                    <span className="medium-text ml-2">Deleted</span>
                  </div>
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      {lab.reports}
                    </div>
                    <span className="medium-text ml-2">Total Reports</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white rounded-full">
                <WavesIcon className="xxs:hidden sm:block w-24 h-24" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-6">
          <div
            className="bg-[var(--color-white)] xxs:p-3 md:p-5 rounded-[10px] flex flex-col space-y-3 w-1/2"
            style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
          >
            <div className="bg-[#AD76F333] rounded-[10px] flex items-center justify-center h-[50px] w-[50px]">
              <DoctorIcon className="w-[30px] h-[30px]" />
            </div>
            <span className="font-medium xxs:text-[20px] md:text-[26px]">
              {lab.users}
            </span>
            <span className="medium-text">Radiologists</span>
            <span className="xxs:text-xs md:text-sm text-blue-text">
              Real-time data on the number of radiologists in the lab.
            </span>
          </div>
          <div
            className="bg-[var(--color-white)] xxs:p-3 md:p-5 rounded-[10px] flex flex-col space-y-3 w-1/2"
            style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
          >
            <div className="bg-[#6DFD6A33] rounded-[10px] flex items-center justify-center h-[50px] w-[50px]">
              <ReportsIcon className="w-[30px] h-[30px]" />
            </div>
            <span className="font-medium xxs:text-[20px] md:text-[26px]">
              {lab.reports}
            </span>
            <span className="medium-text">Reports</span>
            <span className="xxs:text-xs md:text-sm text-blue-text">
              Real-time data on the number of reports created, pending, and
              finalized.
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 flex xxs:flex-col md:flex-row items-center justify-between md:space-y-0 xxs:space-y-4">
        <span className="font-medium xxs:text-s md:text-lg">ALL REPORTS</span>
        <div className="flex xxs:w-full sm:w-3/5 md:w-2/5">
          <TextField
            variant="outlined"
            placeholder="Enter Keyword..."
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #C9CBCD",
                  borderRadius: "8px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #5F6177",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#11171999" : "grey",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "white",
                borderRadius: "8px",
                height: "40px",
                fontSize: "14px",
              },
            }}
            className="w-full"
          />
          <button className="ml-4 p-1.5 rounded-[10px] bg-primary">
            <SearchIcon sx={{ color: "white" }} />
          </button>
        </div>
        <div className="relative cursor-pointer">
          <div
            className="p-2 text-sm border bg-white dark:bg-transparent border-dark-grey rounded-md w-max"
            onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
          >
            <span className="text-[#283037B2] dark:text-grey">Date Range:</span>
            <span className="text-[var(--color-text1)]">
              {" "}
              {dateRange[0].startDate.toDateString()} -{" "}
              {dateRange[0].endDate.toDateString()}
            </span>
            <CalendarMonthOutlinedIcon
              sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
            />
          </div>

          {showDatePickerDropdown && (
            <div
              className="absolute z-10 top-full mt-1 md:right-0 rounded-lg"
              style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
            >
              <DateRangePicker
                ranges={dateRange}
                onChange={(item) => setDateRange([item.selection])}
                locale={enGB}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 grid xxs:grid-cols-1 md:grid-cols-2 gap-6">
        {cardData.map((card, index) => (
          <ReportsCard
            key={index}
            count={card.count}
            label={card.label}
            description={card.description}
            progressColor={card.progressColor}
            progressValue={card.progressValue}
            stats={card.stats}
          />
        ))}
      </div>
    </div>
  );
}

export default LabDetailsPage;
