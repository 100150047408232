import React, { useState, useEffect } from "react";
import PrimaryButton from "../../shared/PrimaryButton";
import { ReactComponent as FolderIcon } from "../../assets/material-symbols_domain-verification.svg";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate, useLocation } from "react-router-dom";
import { forgotPassword } from "../../services/authService"; // Import the OTP service
import { verifyOtp } from "../../services/authService"; // Import the OTP service
import { toast } from "react-toastify"; // Import toast for notifications

const OtpForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [timer, setTimer] = useState(60); // Countdown timer state

  const identifier = location.state?.identifier || "your email or number"; // Get the identifier dynamically

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  const handleChange = (e, index) => {
    const newOtp = [...otp];
    const value = e.target.value;

    // Allow only digits
    if (/^\d*$/.test(value) || value === "") {
      newOtp[index] = value;

      // Automatically focus on the next input field
      if (value.length === 1 && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }

      // Update OTP state
      setOtp(newOtp);
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index]) {
      // Focus on the previous input field if the current one is empty
      if (index > 0) {
        document.getElementById(`otp-${index - 1}`).focus();
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    const otpCode = otp.join("");

    if (otpCode.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP.");
      return;
    }

    try {
      await verifyOtp(identifier, otpCode); // Call the verifyOtp service
      navigate("/reset-password", {
        state: {
          identifier: identifier,
          otp: otpCode,
          fromForgotPassword: true,
        },
      }); // Redirect to reset password page on success
    } catch (error) {
      // Error handling is already included in the service, so no need for additional handling here
    }
  };

  const handleResendOtp = async (e) => {
    if (timer === 0) {
      setTimer(60); // Reset the timer
      try {
        await forgotPassword(identifier); // Call the API method
      } catch (error) {}
    }
  };

  return (
    <div
      className="bg-[var(--color-white)] rounded-[20px] padding-responsive"
      style={{ boxShadow: "0px 9px 34px 0px #0000001A" }}
    >
      <form onSubmit={handleVerifyOtp} className="px-2">
        <div className="flex items-center mt-6">
          <span className="text-[var(--color-dark-grey)] mb-1 font-heading font-semibold large-text mr-2">
            Enter Verification Code
          </span>
          <FolderIcon className="-mt-2 xxs:w-8 xs:w-10" />
        </div>
        <div className="mb-12 text-blue-text text-sm">
          The verification code has been sent to <b>{identifier}</b>
        </div>
        <div className="flex justify-center xxs:space-x-2 xs:space-x-4 sm:space-x-2 lg:space-x-4 xl:space-x-6 mb-12">
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-${index}`}
              type="text"
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)} // Handle backspace
              maxLength={1}
              className="height-responsive width-responsive medium-text border border-grey rounded-lg text-center focus:outline focus:border-0 focus:outline-black focus:ring-4 focus:ring-secondary"
            />
          ))}
        </div>
        <PrimaryButton type="submit" label="Proceed" className="w-full" />
        <div className="text-center text-sm mt-4">
          <button
            type="button"
            className="text-blue-text text-xs underline font-semibold"
            onClick={handleResendOtp}
            disabled={timer > 0}
          >
            Resend OTP:
          </button>{" "}
          <span className="text-xs text-[var(--color-dark-grey)] font-bold">
            {timer > 0 ? `${timer} Sec` : "0 Sec"}
          </span>
        </div>
        <div className="mt-10 mb-6 text-center medium-text flex items-center justify-center">
          <button
            type="button"
            className="text-blue text-sm flex items-center"
            onClick={() => navigate("/login")}
          >
            <ChevronLeftIcon
              className="h-4 w-4 text-blue mr-3"
              aria-hidden="true"
              strokeWidth={3}
            />
            Back to Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default OtpForm;
