import React, { useState } from "react";
import { ReactComponent as ReportsIcon } from "../../assets/reports.svg";
import { ReactComponent as DraftsIcon } from "../../assets/drafts.svg";
import { ReactComponent as FinalizedIcon } from "../../assets/finalized.svg";
import Dropdown from "../../shared/DropDown";

const StatsCard = () => {
  const [selectedTime, setSelectedTime] = useState(null);

  const timeOptions = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "all", label: "All" },
  ];

  const handleOptionChange = (time) => {
    setSelectedTime(time);
  };

  return (
    <div
      className="bg-[var(--color-white)] p-4 rounded-[10px] w-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      {/* Header and Dropdown */}
      <div className="flex justify-between items-center mb-4">
        <span className="text-lg font-medium text-blue-text">Statistics</span>
        <div className="w-40">
          <Dropdown
            id="time"
            label="option"
            options={timeOptions}
            selectedOption={selectedTime}
            onChange={handleOptionChange}
          />
        </div>
      </div>

      {/* Stats Section */}
      <div className="flex flex-wrap justify-between mr-2 space-y-2">
        {/* Number of Reports */}
        <div className="flex items-center">
          <div className="bg-primary-light2 dark:bg-white-light p-2 rounded-full">
            <ReportsIcon className="w-6 h-6" />
          </div>
          <div className="ml-3 text-blue-text">
            <p className="text-xl font-bold">200</p>
            <p className="medium-text">Number of Reports</p>
          </div>
        </div>

        {/* Drafts */}
        <div className="flex items-center">
          <div className="bg-blue-light dark:bg-white-light p-2 rounded-full">
            <DraftsIcon className="w-6 h-6" />
          </div>
          <div className="ml-3 text-blue-text">
            <p className="text-xl font-bold">44</p>
            <p className="medium-text">Drafts</p>
          </div>
        </div>

        {/* Finalized */}
        <div className="flex w-1/4 items-center">
          <div className="bg-error-light dark:bg-white-light p-2 rounded-full">
            <FinalizedIcon className="w-6 h-6" />
          </div>
          <div className="ml-3 text-blue-text">
            <p className="text-xl font-bold">156</p>
            <p className="medium-text">Finalized</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
