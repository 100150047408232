import React from "react";
import { Routes, Route } from "react-router-dom";
import PlanSelection from "../components/subscription/PlanSelection";
import PlanDescription from "../components/subscription/PlanDescription";
import CheckoutPage from "../components/subscription/CheckoutPage";

const SubscriptionPage = () => {
  return (
    <div className="flex h-screen overflow-y-auto padding-responsive bg-bgColor">
      <Routes>
        <Route path="/plans" element={<PlanSelection />} />
        <Route path="/description/:planId" element={<PlanDescription />} />
        <Route path="/checkout/:planId" element={<CheckoutPage />} />
      </Routes>
    </div>
  );
};

export default SubscriptionPage;
