import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as Mp3Icon } from "../../assets/mp3.svg";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../../assets/delete.svg";
import CommonDialog from "../../shared/CommonDialog";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { toast } from "react-toastify";

const TranscriptStep = ({ reportDetails, setReportDetails }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [isEditable, setIsEditable] = useState(false);

  // Function to send audio to OpenAI for transcription
  const transcribeAudio = useCallback(async () => {
    const formData = new FormData();
    formData.append("file", reportDetails.audioFile); // Append the file or blob directly
    formData.append("model", "whisper-1"); // Use OpenAI's Whisper model

    try {
      toast.info("Audio is being transcribed.");
      const response = await axios.post(
        "https://api.openai.com/v1/audio/transcriptions",
        formData,
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const transcribedText = response.data.text;
      if (response) {
        toast.success("Audio transcribed successfully.");
      }
      // Pass the transcribed text to the parent component
      setReportDetails((prevDetails) => ({
        ...prevDetails,
        transcriptText: transcribedText,
      }));
    } catch (error) {
      toast.error(error);
    }
  }, [reportDetails.audioFile, setReportDetails]); // Dependency array

  useEffect(() => {
    if (reportDetails.audioFile) {
      transcribeAudio(); // Automatically call when the component loads
    }
  }, [reportDetails.audioFile, transcribeAudio]);

  const handleEditClick = () => {
    setIsEditable((prev) => !prev); // Toggle editability
  };

  const handleOpenDialog = () => {
    setDialogTitle("Sure you want to delete?");
    setDialogContent("Are you sure you want to delete this?");
    setButtonLabel("Yes, Confirm");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    setReportDetails({
      ...reportDetails,
      audioFile: null,
      audioName: "",
      transcriptText: "",
    });
    setIsEditable(false); // Disable edit mode
    toast.info("Audio and transcript cleared.");
  };

  return (
    <div>
      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Upload Audio File
      </div>
      <div className="relative flex items-center justify-between rounded-2xl p-4 m-6 bg-[var(--color-white)]">
        <div className="flex xxs:flex-col md:flex-row space-y-4 items-center justify-between w-full">
          <div className="flex items-center">
            <Mp3Icon className="mr-4" />
            <div>
              <p className="medium-text font-semibold text-[var(--color-text1)]">
                {reportDetails.audioName}
              </p>
              {/* <p className="text-sm text-gray-500">
                60 KB of 120 KB • Uploading...
              </p> */}
            </div>
          </div>
          <div className="flex space-x-4">
            <button
              type="button"
              className="flex bg-blue p-2 px-3 text-sm font-medium text-white rounded-lg"
            >
              <EditIcon className="mt-0.5 mr-2" />
              Edit
            </button>
            <button
              type="button"
              className="flex bg-error2 p-2 px-3 text-sm font-medium text-white rounded-lg"
              onClick={handleOpenDialog}
            >
              <DeleteIcon className="mt-0.5 mr-2" />
              Delete
            </button>
          </div>
        </div>
      </div>
      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Voice to Text
      </div>
      <div className="relative flex flex-col items-center rounded-lg p-4 m-6 bg-[var(--color-white)]">
        <div className="flex w-full justify-end space-x-4 mb-4">
          <button
            type="button"
            className="flex bg-blue p-2 px-3 text-sm font-medium text-white rounded-lg"
            onClick={handleEditClick}
          >
            <EditIcon className="mt-0.5 mr-2" />
            Edit
          </button>
          <button
            type="button"
            className="flex bg-error2 p-2 px-3 text-sm font-medium text-white rounded-lg"
            onClick={() =>
              setReportDetails({ ...reportDetails, transcriptText: "" })
            }
          >
            <DeleteIcon className="mt-0.5 mr-2" />
            Delete
          </button>
        </div>
        <div className="w-full p-2">
          <TextField
            fullWidth
            minRows={6}
            multiline
            value={reportDetails.transcriptText}
            onChange={(e) =>
              setReportDetails({
                ...reportDetails,
                transcriptText: e.target.value,
              })
            }
            variant="outlined"
            InputProps={{
              readOnly: !isEditable, // Controlled by `isEditable`
            }}
          />
        </div>
      </div>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showRightIcon={false}
        showSideIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default TranscriptStep;
