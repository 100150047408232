const labRecords = [
  {
    labId: "0014",
    name: "Bartho Lab",
    reports: 342,
    users: 70,
    doj: "23 Jul 2024",
    subscription: "Advance",
    location: "Los Angeles, California",
  },
  {
    labId: "0023",
    name: "Medicina Global",
    reports: 234,
    users: 48,
    doj: "12 Aug 2024",
    subscription: "Premium",
    location: "Miami, Florida",
  },
  {
    labId: "AS78",
    name: "Aether BioTech",
    reports: 45,
    users: 10,
    doj: "3 May 2024",
    subscription: "Basic",
    location: "Las Vegas, Nevada",
  },
  {
    labId: "FY79",
    name: "NexGen Radiología",
    reports: 305,
    users: 56,
    doj: "9 Jun 2024",
    subscription: "Advance",
    location: "Seattle, Washington",
  },
  {
    labId: "GD98",
    name: "Clinica Vitae",
    reports: 281,
    users: 63,
    doj: "30 Jun 2024",
    subscription: "Basic",
    location: "Chicago, Illinois",
  },
  {
    labId: "JI89",
    name: "Lumière Labs",
    reports: 234,
    users: 48,
    doj: "8 Jun 2024",
    subscription: "Premium",
    location: "Miami, Florida",
  },
  {
    labId: "23HD",
    name: "Zephyr Labs",
    reports: 45,
    users: 10,
    doj: "12 Sep 2024",
    subscription: "Advance",
    location: "Las Vegas, Nevada",
  },
  {
    labId: "3J49",
    name: "Aether BioTech",
    reports: 305,
    users: 56,
    doj: "4 Aug 2024",
    subscription: "Premium",
    location: "Seattle, Washington",
  },
  {
    labId: "28DK",
    name: "Clinica Vitae",
    reports: 281,
    users: 63,
    doj: "1 Mar 2024",
    subscription: "Premium",
    location: "Chicago, Illinois",
  },
  {
    labId: "3MS9",
    name: "Lumière Labs",
    reports: 234,
    users: 87,
    doj: "4 Jun 2024",
    subscription: "Advance",
    location: "Miami, Florida",
  },
  {
    labId: "4KL2",
    name: "GenTech Labs",
    reports: 190,
    users: 38,
    doj: "5 Jul 2024",
    subscription: "Basic",
    location: "Austin, Texas",
  },
  {
    labId: "ZT45",
    name: "Prime Radiology",
    reports: 298,
    users: 62,
    doj: "18 Jul 2024",
    subscription: "Advance",
    location: "New York, New York",
  },
  {
    labId: "MP32",
    name: "Helix Labs",
    reports: 150,
    users: 29,
    doj: "28 Feb 2024",
    subscription: "Basic",
    location: "Denver, Colorado",
  },
  {
    labId: "HG67",
    name: "Biolink Diagnostics",
    reports: 400,
    users: 89,
    doj: "10 Jan 2024",
    subscription: "Premium",
    location: "Boston, Massachusetts",
  },
  {
    labId: "9FD3",
    name: "ClearView Labs",
    reports: 320,
    users: 75,
    doj: "5 Mar 2024",
    subscription: "Advance",
    location: "Phoenix, Arizona",
  },
  {
    labId: "5WR2",
    name: "Optima Labs",
    reports: 210,
    users: 47,
    doj: "15 Apr 2024",
    subscription: "Basic",
    location: "Seattle, Washington",
  },
  {
    labId: "BQ12",
    name: "Luxe Diagnostics",
    reports: 185,
    users: 33,
    doj: "20 May 2024",
    subscription: "Advance",
    location: "Chicago, Illinois",
  },
  {
    labId: "67PL",
    name: "FuturePath Labs",
    reports: 290,
    users: 66,
    doj: "14 Jun 2024",
    subscription: "Premium",
    location: "Dallas, Texas",
  },
  {
    labId: "9XC5",
    name: "Nova Labs",
    reports: 410,
    users: 92,
    doj: "25 Apr 2024",
    subscription: "Advance",
    location: "San Francisco, California",
  },
  {
    labId: "3PQ7",
    name: "Precision LabTech",
    reports: 215,
    users: 52,
    doj: "11 Jul 2024",
    subscription: "Basic",
    location: "Houston, Texas",
  },
];

export default labRecords;
