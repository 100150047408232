import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import labData from "../constants/labData";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommonDialog from "../shared/CommonDialog";

function LabManagementPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const labs = labData;
  const [rowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [activeRow, setActiveRow] = useState(null); // Track the active row for the dropdown
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null); // Track hovered row index for the hover effect
  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);

  const dropdownRef = useRef(null); // Ref for detecting clicks outside
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#513ADC",
    },
  ]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const totalPages = Math.ceil(labs.length / rowsPerPage);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Sure You Want To Delete Account?");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Yes");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Add your confirm logic here
    setDialogOpen(false);
  };

  const handleActionSelect = () => {
    setActiveRow(null); // Close the dropdown after selection
    handleOpenDialog();
  };

  const toggleActionDropdown = (rowIndex) => {
    setActiveRow((prevActiveRow) =>
      prevActiveRow === rowIndex ? null : rowIndex
    ); // Toggle dropdown for the clicked row
  };

  const getStatusStyle = (subscription) => {
    if (subscription === "Advance") {
      return {
        backgroundColor: theme.palette.mode === "light"? "#E5FFEB" : "#2A2D3E",
        // backgroundColor: "#E5FFEB",
        color: "#28C76F",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    } else if (subscription === "Premium") {
      return {
        backgroundColor: theme.palette.mode === "light" ? "#FFEDED" : "#2A2D3E",
        color: "#FF4C51",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    } else {
      return {
        backgroundColor: theme.palette.mode === "light" ? "#DFFFFF" : "#2A2D3E",
        color: "#00BAD1",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    }
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRowClick = (lab) => {
    navigate(`/lab-details/${lab.labId}`, { state: { lab } });
  };

  return (
    <div className="p-6">
      <div className="flex xxs:flex-col sm:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between">
        <div className="flex xxs:flex-col lg:flex-row xxs:space-y-4 lg:space-y-0 w-full lg:items-center justify-start lg:space-x-4">
          <div className="relative cursor-pointer">
            <div
              className="p-2 text-sm border bg-white dark:bg-transparent border-dark-grey rounded-md w-max"
              onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
            >
              <span className="text-[#283037B2] dark:text-grey">
                Date Range:
              </span>
              <span className="text-[var(--color-text1)]">
                {" "}
                {dateRange[0].startDate.toDateString()} -{" "}
                {dateRange[0].endDate.toDateString()}
              </span>
              <CalendarMonthOutlinedIcon
                sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
              />
            </div>

            {showDatePickerDropdown && (
              <div
                className="absolute z-10 top-full mt-1 md:left-0 rounded-lg"
                style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
              >
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(item) => setDateRange([item.selection])}
                  locale={enGB}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex xxs:w-full lg:w-1/2 sm:justify-end">
          <TextField
            variant="outlined"
            placeholder="Enter Keyword..."
            className="w-full"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #5F6177",
                  borderRadius: "8px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #5F6177",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#11171999" : "grey",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "white",
                borderRadius: "8px",
                height: "45px",
                fontSize: "14px",
              },
            }}
          />
          <button className="ml-2 p-2.5 rounded-lg bg-primary">
            <SearchIcon sx={{ color: "white" }} />
          </button>
        </div>
      </div>
      <div className="mt-6 rounded-2xl">
        <TableContainer
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "white" : "#1A1A1A",
            paddingTop: "15px",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>LAB ID</TableCell>
                <TableCell>LABORATORY NAME</TableCell>
                <TableCell>REPORTS</TableCell>
                <TableCell>USERS</TableCell>
                <TableCell>DOJ</TableCell>
                <TableCell>SUBSCRIPTION</TableCell>
                <TableCell>LOCATION</TableCell>
                <TableCell>ACTION</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {labs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lab, index) => (
                  <TableRow
                    key={lab.labId}
                    onClick={() => handleRowClick(lab)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{lab.labId}</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: { xxs: "14px", md: "16px" },
                      }}
                    >
                      {lab.name}
                    </TableCell>
                    <TableCell>{lab.reports}</TableCell>
                    <TableCell>{lab.users}</TableCell>
                    <TableCell>{lab.doj}</TableCell>
                    <TableCell>
                      <span style={getStatusStyle(lab.subscription)}>
                        {lab.subscription}
                      </span>
                    </TableCell>
                    <TableCell>{lab.location}</TableCell>
                    <TableCell
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent row click event
                      }}
                    >
                      <div className="relative m-0 p-0">
                        <button
                          className="p-2 ml-2 w-10 rounded-full"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent row click event
                            toggleActionDropdown(index);
                          }}
                          onMouseEnter={() => setHoveredRowIndex(index)}
                          onMouseLeave={() => setHoveredRowIndex(null)}
                          style={{
                            backgroundColor:
                              hoveredRowIndex === index
                                ? theme.palette.mode === "light"
                                  ? "#F5F5F5"
                                  : "#2A2D3E"
                                : "transparent",
                          }}
                        >
                          <MoreVertIcon
                            sx={{ fontSize: "18px", color: "#666666" }}
                          />
                        </button>

                        {activeRow === index && ( // Render dropdown only for active row
                          <div
                            ref={dropdownRef} // Reference for outside click detection
                            className="absolute top-full -left-10 z-10 mt-0.5 bg-[var(--color-white)] w-20 rounded-[10px]"
                            style={{
                              border: "1px solid #E7E7E7",
                              boxShadow: "0px 4px 16px 0px #00000029",
                            }}
                          >
                            <p
                              className={`cursor-pointer small-text m-0 p-2 hover:bg-grey2 dark:hover:bg-white-light text-center rounded-[10px]`}
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent row click
                                handleActionSelect();
                              }}
                            >
                              Delete
                            </p>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* MUI Pagination */}
        <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between bg-[var(--color-white)] mt-4 py-5 px-4">
          <span className="text-[var(--color-dark-grey)] text-sm font-medium">
            Showing {page * rowsPerPage + 1} to{" "}
            {Math.min((page + 1) * rowsPerPage, labs.length)} of {labs.length}{" "}
            entries
          </span>
          <Pagination
            count={totalPages}
            page={page + 1}
            onChange={handlePageChange}
            siblingCount={1}
            sx={{
              "& .MuiPaginationItem-root": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode background
                    : "#2A2D3E", // Dark mode background
                borderRadius: "4px",
                color:
                  theme.palette.mode === "light"
                    ? "#5F6177" // Light mode text color
                    : "#A1A1B1", // Dark mode text color
                fontWeight: "600",
                width: "36px", // Set a fixed width for consistency
                height: "36px", // Set a fixed height for consistency
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "4px 4px",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#513ADC",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#513ADC" // Light mode selected hover
                      : "#836FFF", // Dark mode selected hover
                },
              },
              "& .MuiPaginationItem-root.Mui-disabled": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode disabled background
                    : "#1E1E2F", // Dark mode disabled background
                color:
                  theme.palette.mode === "light"
                    ? "#B9B9B9" // Light mode disabled text
                    : "#5F6177", // Dark mode disabled text
              },
            }}
          />
        </div>
      </div>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDeleteIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default LabManagementPage;
