import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

export const createReportTemplates = async (reportData) => {
  try {
    const response = await apiClient.post(
      "/report-template/create-report-template",
      reportData
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Report creation failed!"); // Error toast
    throw error.response.data;
  }
};

export const getReportTemplates = async () => {
  try {
    const response = await apiClient.get("/report-template/get-report-templates");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to fetch reports!"); // Error toast
    throw error.response.data;
  }
};

export const getReportTemplateById = async (reportId) => {
  try {
    const response = await apiClient.get(
      `/report-template/get-report-template/${reportId}`
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to fetch report!"); // Error toast
    throw error.response.data;
  }
};

export const editReportTemplate = async (reportId, payload) => {
  try {
    const response = await apiClient.put(
      `/report-template/edit-report-template/${reportId}`,
      payload
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to delete report!"); // Error toast
    throw error.response.data;
  }
};
