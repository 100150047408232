import React from "react";
import PlanCard from "./PlanCard";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { plans } from "../../constants/planData"; // Import the plan data

const PlanSelection = () => {
  const navigate = useNavigate();

  const handleChoosePlan = (planId) => {
    navigate(`/subscription/description/${planId}`);
  };

  return (
    <div className="bg-[var(--color-white)] rounded-lg w-full table">
      <div className="p-5" style={{ boxShadow: "0px 2px 4px 0px #0000002E" }}>
        <button
          type="button"
          className="text-[var(--color-text1)] flex items-center"
          onClick={() => navigate("/login")}
        >
          <ChevronLeftIcon
            className="h-4 w-4 text-[var(--color-text1)] mr-3 medium-text"
            aria-hidden="true"
            strokeWidth={3}
          />
          Back to Login
        </button>
      </div>
      <div className="text-center mt-10">
        <span className="extra-large-text font-semibold text-[var(--color-text1)]">
          Choose Your Plan
        </span>
      </div>
      <div className="flex xxs:flex-col xxs:items-center sm:flex-row sm:items-end xxs:m-4 gap-10 md:m-10">
        {plans.map((plan) => (
          <PlanCard
            key={plan.id}
            planId={plan.id}
            title={plan.title}
            price={plan.price}
            features={plan.features}
            onChoose={() => handleChoosePlan(plan.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default PlanSelection;
