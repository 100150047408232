import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../shared/PrimaryButton";
import CommonDialog from "../shared/CommonDialog";
import CancelButton from "../shared/CancelButton";
import RichTextEditor from "../shared/RichTextEditor";

function EditReportPage({ reportDetails }) {
  const location = useLocation();
  const navigate = useNavigate();
  const fromSubmitStep = location.state?.fromSubmitStep || false;
  const fromSettings = location.state?.fromSettings || false;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [showDraftIcon, setShowDraftIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(false);

  const handleOpenDialog1 = () => {
    setDialogTitle("Report Finalized Successfully!");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Okay");
    setDialogOpen(true);
    setShowDraftIcon(false);
    setShowRightIcon(true);
  };

  const handleOpenDialog2 = () => {
    setDialogTitle("Report Saved To Draft!");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Okay");
    setDialogOpen(true);
    setShowDraftIcon(true);
    setShowRightIcon(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Add your confirm logic here
    console.log("Saved successfully!");
  };

  return (
    <div className="flex flex-col h-full">

      <RichTextEditor fromSubmitStep={fromSubmitStep} reportDetails={reportDetails} />

      <div
        className="md:sticky bottom-0 right-0 z-10 flex bg-[var(--color-white)]"
        style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
      >
        <div className="flex flex-row justify-between xxs:p-2 md:p-6 space-x-4 w-full">
          <div>
            {fromSettings && (
              <CancelButton
                type="button"
                label="Cancel"
                className="md:w-32"
                onClick={() => navigate(-1)}
              />
            )}
          </div>
          <div className="flex flex-row space-x-4">
            {fromSubmitStep && (
              <button
                className="bg-[var(--color-white)] border border-primary text-[var(--color-primary)] xxs:px-4 md:px-8 rounded-lg font-medium text-sm"
                onClick={handleOpenDialog2}
              >
                Save as Draft
              </button>
            )}
            <PrimaryButton
              type="button"
              label={fromSubmitStep ? "Finalize" : "Save Changes"}
              className="md:w-32"
              onClick={handleOpenDialog1}
            />
          </div>
        </div>
      </div>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDraftIcon={showDraftIcon}
        showRightIcon={showRightIcon}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default EditReportPage;
