import React from 'react';
import { useTheme } from "@mui/material/styles";
import { Chip as MuiChip } from '@mui/material';

const Chip = ({ label, barColor }) => {
  const theme = useTheme();

  return (
    <MuiChip
      label={label}
      sx={{
        color: barColor ? barColor : theme.palette.mode === 'dark' ? '#B9B9B9' : '#5F6177', // Use barColor if provided, otherwise black
        backgroundColor: barColor ? `${barColor}10` : theme.palette.mode === 'dark' ? '#2A2D3E' : '#FFFFFF', // Lighter version of barColor, or white if not provided
        borderRadius: '22px',
        fontWeight: '500',
        padding: '4px 14px',
        boxShadow: '0px 4px 16px 0px #00000029',
        fontSize: 'medium-text',
      }}
    />
  );
};

export default Chip;
