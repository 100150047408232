import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Dropdown from "../shared/DropDown";
import DataTable from "../shared/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import { getReports } from "../services/reportService";

function ReportManagementPage() {
  const theme = useTheme();
  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#513ADC",
    },
  ]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [reports, setReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filtersToApply, setFiltersToApply] = useState({
    reportType: null,
    status: null,
    query: "",
  });
  const [filteredReports, setFilteredReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const reportOptions = [
    { value: "x-ray", label: "X-Ray" },
    { value: "mri", label: "MRI" },
    { value: "ecg", label: "ECG" },
    { value: "ct-scan", label: "CT-Scan" },
    { value: "sonography", label: "Sonography" },
  ];

  const statusOptions = [
    { value: "Draft", label: "Draft" },
    { value: "Finalized", label: "Finalized" },
    { value: "All", label: "All" },
  ];

  const fetchReports = async () => {
    setIsLoading(true);
    try {
      const response = await getReports();
      setReports(response.reports);
      setFilteredReports(response.reports);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  const applyFilters = useCallback(() => {
    const { reportType, status, query } = filtersToApply;
    const filtered = reports.filter((report) => {
      const matchesReportType = !reportType || report.report_type === reportType.label;
      const matchesStatus =
        !status || status.value === "All" || report.status === status.value;
      const matchesSearchQuery = query
        ? report.patientName?.toLowerCase().includes(query.toLowerCase())
        : true;
      return matchesReportType && matchesStatus && matchesSearchQuery;
    });
    setFilteredReports(filtered);
  }, [filtersToApply, reports]);

  const handleSearchClick = () => {
    setFiltersToApply({
      reportType: selectedReport,
      status: selectedStatus,
      query: searchQuery,
    });
  };

  // Automatically apply filters when `filtersToApply` changes
  useEffect(() => {
    applyFilters();
  }, [filtersToApply, applyFilters]);

  return (
    <div className="p-6">
      <div className="flex xxs:flex-col sm:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between">
        <div className="flex xxs:flex-col lg:flex-row xxs:space-y-4 lg:space-y-0 w-full lg:items-center justify-start lg:space-x-4">
          <div className="relative cursor-pointer">
            <div
              className="p-2 text-sm border bg-white dark:bg-transparent border-dark-grey rounded-md w-max"
              onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
            >
              <span className="text-[#283037B2] dark:text-grey">
                Date Range:
              </span>
              <span className="text-[var(--color-text1)]">
                {dateRange[0].startDate.toDateString()} -{" "}
                {dateRange[0].endDate.toDateString()}
              </span>
              <CalendarMonthOutlinedIcon
                sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
              />
            </div>

            {showDatePickerDropdown && (
              <div
                className="absolute z-10 top-full mt-1 md:left-0 rounded-lg"
                style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
              >
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(item) => setDateRange([item.selection])}
                  locale={enGB}
                />
              </div>
            )}
          </div>
          <div className="flex space-x-4 w-full">
            <div className="md:w-2/5">
              <Dropdown
                id="report-type"
                label="report type"
                options={reportOptions}
                selectedOption={selectedReport}
                onChange={setSelectedReport}
                showFilter={true}
              />
            </div>
            <div className="md:w-1/3">
              <Dropdown
                id="status"
                label="option"
                options={statusOptions}
                selectedOption={selectedStatus}
                onChange={setSelectedStatus}
              />
            </div>
          </div>
        </div>
        <div className="flex xxs:w-full lg:w-2/6 sm:justify-end">
          <TextField
            variant="outlined"
            placeholder="Search patient name..."
            className="w-full"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #5F6177",
                  borderRadius: "8px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #5F6177",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#11171999" : "grey",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "white",
                borderRadius: "8px",
                height: "45px",
                fontSize: "14px",
              },
            }}
          />
          <button
            className="ml-2 p-2.5 rounded-lg bg-primary"
            onClick={handleSearchClick}
          >
            <SearchIcon sx={{ color: "white" }} />
          </button>
        </div>
      </div>
      <div className="mt-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <CircularProgress />
          </div>
        ) : (
          <DataTable rows={10} showRow={true} reports={filteredReports} />
        )}
      </div>
    </div>
  );
}

export default ReportManagementPage;
