import React from "react";
import { PieChart } from "@mui/x-charts/PieChart";

const PatientProfile = () => {
  const data = [
    { label: "Male", value: 50, color: "#FCAA5C" },
    { label: "Female", value: 35, color: "#28C76F" },
    { label: "Others", value: 15, color: "#FF4C51" },
  ];

  return (
    <div
      className="bg-[var(--color-white)] p-4 rounded-[10px] w-full h-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      {/* Header */}
      <div className="mb-4 p-2">
        <span className="text-lg font-medium text-blue-text">
          Patient's Profile
        </span>
      </div>

      <div className="flex justify-center xxs:space-x-2 md:space-x-6 lg:space-x-8">
        {/* Pie Chart */}
        <div className="w-2/5">
          <PieChart
            height={100} // Adjusted height for better visibility
            width={230} // Set width to ensure it doesn't overflow
            series={[
              {
                data: data,
                innerRadius: 35,
                outerRadius: 50,
                color: (index) => data[index].color,
                startAngle: -45,
              },
            ]}
            slotProps={{
              legend: { hidden: true },
            }}
          />
        </div>

        {/* Custom Legend */}
        <div className="flex flex-col mt-2 w-3/5">
          {data.map((item, index) => (
            <div key={index} className="flex items-center mb-2">
              <div
                className="w-2 h-2 mr-2 rounded-full" // Change to rounded shape
                style={{ backgroundColor: item.color }}
              />
              <span className="text-sm text-[var(--color-dark-grey)]">
                {item.label}
              </span>
              <span className="xxs:ml-auto md:ml-2 lg:ml-auto text-sm text-[var(--color-text1)]">
                {item.value}%
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatientProfile;
