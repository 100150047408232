export const plans = [
  {
    title: "Basic",
    description:
      "The Standard Plan is tailored for medium-sized labs seeking to streamline operations with enhanced management and reporting capabilities. It offers a balance of features that support efficient lab workflows, equipment management, and compliance tracking, making it an ideal choice for growing labs that require more advanced tools without the complexity of an enterprise solution.",
    price: 24.67,
    features: [
      { name: "User Access: Up to 5 users", available: true },
      { name: "Inventory Management: Track up to 500 items", available: true },
      {
        name: "Equipment Management: Basic tracking and maintenance ",
        available: true,
      },
      {
        name: "Data Reports: Monthly usage and inventory reports",
        available: true,
      },
      {
        name: "Support: Email support with 48-hour response time",
        available: false,
      },
    ],
    detailedFeatures: [
      {name: "User Access: Allows up to 20 users, enabling multiple team members to collaborate and manage lab operations efficiently."},
      {name: "Inventory Management: Track up to 2,000 lab items, ensuring your supplies are always organized and stock levels are easy to monitor."},
      {name: "Equipment Management: Schedule maintenance and track performance for up to 25 pieces of lab equipment, ensuring your instruments remain in optimal working condition."},
      {name: "Lab Scheduling: Includes an equipment and lab space booking system, which helps prevent double-booking and ensures resources are allocated effectively."},
      {name: "Data Reports: Generate detailed weekly reports, exportable in CSV and PDF formats, providing insights into inventory usage, equipment performance, and overall lab efficiency."},
      {name: "Audit Logs: Record all changes made in the system, providing a transparent audit trail for compliance purposes, ensuring that your lab meets industry standards."},
      {name: "Support: Enjoy priority email support with a guaranteed 24-hour response time, giving you peace of mind when issues arise."},
    ],
    id: "basic",
  },
  {
    title: "Advance",
    description:
      "The Standard Plan is tailored for medium-sized labs seeking to streamline operations with enhanced management and reporting capabilities. It offers a balance of features that support efficient lab workflows, equipment management, and compliance tracking, making it an ideal choice for growing labs that require more advanced tools without the complexity of an enterprise solution.",
    price: 59.9,
    features: [
      { name: "Up to 20 users", available: true },
      { name: "Track up to 2000 items", available: true },
      {
        name: "Maintenance schedules for up to 25 pieces of equipment",
        available: true,
      },
      { name: "Equipment and lab space booking system", available: true },
      { name: "Weekly reports, exportable to CSV and PDF", available: true },
      {
        name: "Support: Email support with 48-hour response time",
        available: false,
      },
      {
        name: "Record of changes in the system for compliance purposes",
        available: false,
      },
    ],
    detailedFeatures: [
      {name: "User Access: Allows up to 20 users, enabling multiple team members to collaborate and manage lab operations efficiently."},
      {name: "Inventory Management: Track up to 2,000 lab items, ensuring your supplies are always organized and stock levels are easy to monitor."},
      {name: "Equipment Management: Schedule maintenance and track performance for up to 25 pieces of lab equipment, ensuring your instruments remain in optimal working condition."},
      {name: "Lab Scheduling: Includes an equipment and lab space booking system, which helps prevent double-booking and ensures resources are allocated effectively."},
      {name: "Data Reports: Generate detailed weekly reports, exportable in CSV and PDF formats, providing insights into inventory usage, equipment performance, and overall lab efficiency."},
      {name: "Audit Logs: Record all changes made in the system, providing a transparent audit trail for compliance purposes, ensuring that your lab meets industry standards."},
      {name: "Support: Enjoy priority email support with a guaranteed 24-hour response time, giving you peace of mind when issues arise."},
    ],
    id: "advance",
  },
  {
    title: "Premium",
    description:
      "The Standard Plan is tailored for medium-sized labs seeking to streamline operations with enhanced management and reporting capabilities. It offers a balance of features that support efficient lab workflows, equipment management, and compliance tracking, making it an ideal choice for growing labs that require more advanced tools without the complexity of an enterprise solution.",
    price: 120.7,
    features: [
      { name: "Unlimited users", available: true },
      {
        name: "Unlimited tracking with barcode/QR code integration",
        available: true,
      },
      { name: "Equipment and lab space booking system", available: true },
      {
        name: "Data Reports: Monthly usage and inventory reports",
        available: true,
      },
      { name: "Weekly reports, exportable to CSV and PDF", available: true },
      {
        name: "Support: Email support with 48-hour response time",
        available: true,
      },
    ],
    detailedFeatures: [
      {name: "User Access: Allows up to 20 users, enabling multiple team members to collaborate and manage lab operations efficiently."},
      {name: "Inventory Management: Track up to 2,000 lab items, ensuring your supplies are always organized and stock levels are easy to monitor."},
      {name: "Equipment Management: Schedule maintenance and track performance for up to 25 pieces of lab equipment, ensuring your instruments remain in optimal working condition."},
      {name: "Lab Scheduling: Includes an equipment and lab space booking system, which helps prevent double-booking and ensures resources are allocated effectively."},
      {name: "Data Reports: Generate detailed weekly reports, exportable in CSV and PDF formats, providing insights into inventory usage, equipment performance, and overall lab efficiency."},
      {name: "Audit Logs: Record all changes made in the system, providing a transparent audit trail for compliance purposes, ensuring that your lab meets industry standards."},
      {name: "Support: Enjoy priority email support with a guaranteed 24-hour response time, giving you peace of mind when issues arise."},
    ],
    id: "premium",
  },
];
