import React from "react";
import { useTheme } from "@mui/material/styles";
import { Dialog } from "@headlessui/react";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import PrimaryButton from "./PrimaryButton";
import { TextField } from "@mui/material";
import { ReactComponent as DraftIcon } from "../assets/draft.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";

const CommonDialog = ({
  open,
  onClose,
  title,
  content,
  buttonLabel,
  textBox,
  audioName,
  onAudioNameChange,
  showRightIcon,
  showDraftIcon,
  showSideIcon,
  onConfirm,
  showDeleteIcon,
}) => {
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
          <Dialog.Panel className="relative bg-[var(--color-white)] rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            {/* Close button in top-right corner */}
            <button
              type="button"
              className="absolute top-0 right-0 m-4 p-1 bg-grey2 dark:bg-white-light rounded-full"
              onClick={onClose}
            >
              <XMarkIcon
                className="h-6 w-6 text-[var(--color-primary)]"
                aria-hidden="true"
                strokeWidth={2}
              />
            </button>

            {/* Main content */}
            <div className="p-10">
              <div
                className={`mb-4 ${
                  showRightIcon ? "text-center" : "text-left"
                }`}
              >
                {showRightIcon && (
                  <div className="mx-auto flex items-center justify-center h-12 w-12">
                    <CheckCircleIcon
                      className="h-12 w-12 text-main2"
                      aria-hidden="true"
                    />
                  </div>
                )}
                {showDeleteIcon && (
                  <div className="mx-auto flex items-center justify-center h-12 w-12">
                    <DeleteIcon
                      className="h-12 w-12 text-error"
                      aria-hidden="true"
                    />
                  </div>
                )}
                {showDraftIcon && (
                  <div className="mx-auto flex items-center justify-center h-12 w-12">
                    <DraftIcon />
                  </div>
                )}
                <div className="flex flex-row justify-start items-start mt-3 sm:mt-5">
                  {showSideIcon && (
                    <div
                      className="flex p-2.5 mb-2 rounded-lg"
                      style={{ backgroundColor: "#F5F5F5" }}
                    >
                      <CheckCircleIcon
                        className="h-9 w-9 text-[var(--color-text1)]"
                        aria-hidden="true"
                      />
                    </div>
                  )}
                  <div
                    className={`ml-4 flex flex-col w-full ${
                      showSideIcon ? "text-left" : "text-center"
                    }`}
                  >
                    {/* Render dynamic title */}
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-semibold text-[var(--color-text1)]"
                    >
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      {/* Render dynamic content */}
                      <p className="text-sm text-blue-text">{content}</p>
                    </div>
                  </div>
                </div>
              </div>

              {textBox && (
                <div className="mb-4">
                  <TextField
                    variant="outlined"
                    placeholder="Enter Audio Name"
                    value={audioName} // Controlled input
                    onChange={(e) => {
                      const value = e.target.value;
                      if (onAudioNameChange) onAudioNameChange(value);
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #C9CBCD",
                          borderRadius: "8px !important",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1px solid #5F6177",
                        },
                      },
                    }}
                    InputProps={{
                      style: {
                        backgroundColor:
                          theme.palette.mode === "dark"
                            ? "transparent"
                            : "white",
                        borderRadius: "8px",
                        height: "40px",
                        fontSize: "14px",
                      },
                    }}
                    className="w-full"
                  />
                </div>
              )}

              {/* Action button */}
              <PrimaryButton
                type="button"
                label={buttonLabel}
                className="mt-4 w-full"
                onClick={() => {
                  onConfirm();
                  onClose();
                }}
              />
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default CommonDialog;
