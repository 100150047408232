import React, { useState } from "react";
import PrimaryButton from "../../shared/PrimaryButton";
import TextInput from "../../shared/TextInput";
import CircleIcon from "@mui/icons-material/Circle";
import { changePassword } from "../../services/userService";
import { toast } from "react-toastify";

function Security() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleInputChange = (e, setState) => {
    setState(e.target.value);
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    const passwordData = {
      current_password: currentPassword,
      new_password: newPassword,
    };

    try {
      await changePassword(passwordData);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {}
  };

  const validatePassword = (password) => {
    const minLength = password.length >= 8;
    const hasLowercase = /[a-z]/.test(password);
    const hasNumberSymbol = /[0-9!@#$%^&*(),.?":{}|<>]/.test(password);

    if (!minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!hasLowercase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumberSymbol) {
      return "Password must contain at least one number, symbol, or whitespace.";
    }
    return "";
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setNewPassword(password);

    const error = validatePassword(password);
    setPasswordError(error);
  };

  const isFormValid = 
    currentPassword &&
    newPassword &&
    confirmPassword &&
    newPassword === confirmPassword &&
    !passwordError;

  return (
    <div className="flex flex-col min-h-full">
      <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Change Password
      </div>

      <div
        className="m-6 bg-[var(--color-white)] py-6 px-4 rounded-[10px] space-y-6"
        style={{ border: "1px solid #CFD8DC" }}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-16">
          <TextInput
            id="current-password"
            type="password"
            label="Current Password"
            placeholder="Enter Password"
            value={currentPassword}
            onChange={(e) => handleInputChange(e, setCurrentPassword)}
            className="mb-2"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-16">
          <TextInput
            id="new-password"
            type="password"
            label="New Password"
            placeholder="Enter Password"
            value={newPassword}
            onChange={handlePasswordChange}
            className="mb-2"
            error={!!passwordError}
            helperText={passwordError}
          />
          <TextInput
            id="confirm-password"
            type="password"
            label="Confirm Password"
            placeholder="Enter Password"
            value={confirmPassword}
            onChange={(e) => handleInputChange(e, setConfirmPassword)}
            className="mb-2"
          />
        </div>

        <div className="medium-text text-[var(--color-dark-grey)]">
          <p>Password Requirements:</p>
          <ul>
            <li>
              <CircleIcon
                sx={{
                  color: "#5F6177",
                  fontSize: {
                    xxs: "5px",
                    md: "6px",
                  },
                  mx: 1,
                  mt: "-0.2rem",
                }}
              />
              Minimum 8 characters long - the more, the better
            </li>
            <li>
              <CircleIcon
                sx={{
                  color: "#5F6177",
                  fontSize: {
                    xxs: "5px",
                    md: "6px",
                  },
                  mx: 1,
                  mt: "-0.2rem",
                }}
              />
              At least one lowercase character
            </li>
            <li>
              <CircleIcon
                sx={{
                  color: "#5F6177",
                  fontSize: {
                    xxs: "5px",
                    md: "6px",
                  },
                  mx: 1,
                  mt: "-0.2rem",
                }}
              />
              At least one number, symbol, or whitespace character
            </li>
          </ul>
        </div>
      </div>

      <div
        className="fixed bottom-0 left-0 right-0 flex bg-[var(--color-white)]"
        style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
      >
        <div className="flex flex-row justify-end xxs:p-2 md:p-6 w-full">
          <PrimaryButton
            type="button"
            label="Save Changes"
            className="md:w-40"
            onClick={handleChangePassword}
            disabled={!isFormValid}
          />
        </div>
      </div>
    </div>
  );
}

export default Security;
