import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import PrimaryButton from "../../shared/PrimaryButton";
import { ReactComponent as CloudIcon } from "../../assets/cloud-add.svg";
import { ReactComponent as VoiceIcon } from "../../assets/voice.svg";
import { ReactComponent as UploadIcon } from "../../assets/upload-light.svg";
import { ReactComponent as Mp3Icon } from "../../assets/mp3.svg";
import { ReactComponent as MicIcon } from "../../assets/mic.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CommonDialog from "../../shared/CommonDialog";
import LinearProgress from "@mui/material/LinearProgress";
import { CircularProgress } from "@mui/material";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";

const UploadAudioStep = ({ reportDetails, setReportDetails, setFormValid }) => {
  const theme = useTheme();
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileSize, setFileSize] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const recorderControls = useVoiceVisualizer();
  const [audioName, setAudioName] = useState("");
  const {
    // ... (Extracted controls and states, if necessary)
    recordedBlob,
    error,
  } = recorderControls;

  // Get the error when it occurs
  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  const handleOpenDialog = () => {
    setOpen(false);
    setDialogTitle("Save Audio");
    setDialogContent("Name your voice note");
    setButtonLabel("Save");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const saveAudioFile = (blob) => {
    const file = new File([blob], audioName + ".mp3", {
      type: "audio/mp3",
    });
    setUploadedFile(file);
    setFileSize((file.size / (1024 * 1024)).toFixed(2)); // Convert size to MB
    setReportDetails({
      ...reportDetails,
      audioFile: file,
      audioName: audioName,
    });
  };

  const handleConfirm = () => {
    saveAudioFile(recordedBlob);
    handleCloseDialog(); // Close the dialog after saving
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileUpload = (file) => {
    if (file) {
      setUploadedFile(file);
      setReportDetails({
        ...reportDetails,
        audioFile: file,
        audioName: file.name,
      });
      setFileSize((file.size / (1024 * 1024)).toFixed(2)); // Convert size to MB
      setIsUploading(true);

      // Reset the file input after selecting file
      fileInputRef.current.value = "";

      // Simulate file upload progress
      const uploadInterval = setInterval(() => {
        setUploadProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(uploadInterval);
            setIsUploading(false); // Upload complete
            return 100;
          }
          return prevProgress + 10;
        });
      }, 300);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
    fileInputRef.current.value = "";
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    handleFileUpload(file);
    fileInputRef.current.value = ""; // Close the file input
  };

  const handleCancelUpload = () => {
    setUploadedFile(null);
    setUploadProgress(0);
    setFileSize(0);
    setIsUploading(false);
  };

  return (
    <div>
      {/* Header */}
      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Upload Audio File
      </div>

      {/* Upload Options */}
      <div className="flex xxs:flex-col sm:flex-row justify-around m-4">
        {/* File Upload Section */}
        <div
          className={`flex flex-col items-center justify-center border-dashed border border-light-grey xxs:w-auto sm:w-2/3 p-6 m-4 bg-[var(--color-white)] ${
            isDragging ? "bg-gray-200" : ""
          }`}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <CloudIcon className="w-12 h-12 mb-4 text-[var(--color-text1)]" />
          <p className="text-center text-[var(--color-text1)] text-xl mb-1">
            Choose a file or drag & drop it here
          </p>
          <p className="text-center mb-4 text-sm text-gray-500">
            MP3 formats, up to 50MB
          </p>
          <PrimaryButton
            type="button"
            label="Browse File"
            className="mt-2 px-10 text-lg font-semibold"
            onClick={() => fileInputRef.current.click()}
            leftIcon={<UploadIcon className="w-6 h-6" />}
          />
          <input
            type="file"
            id="file-upload"
            accept=".mp3"
            onChange={handleFileChange}
            className="hidden"
            ref={fileInputRef}
          />
        </div>

        {/* Audio Recording Section */}
        <div className="flex flex-col items-center justify-center border-dashed border border-light-grey xxs:w-auto sm:w-1/2 p-6 m-4 bg-[var(--color-white)]">
          <VoiceIcon className="w-12 h-12 mb-4 text-[var(--color-text1)]" />
          <p className="text-center text-[var(--color-text1)] text-xl mb-1">
            Record your voice by using below button
          </p>
          <p className="text-center mb-4 text-sm text-gray-500">
            MP3 formats, up to 50MB
          </p>
          <PrimaryButton
            type="button"
            label="Start Recording"
            className="mt-2 px-10 text-lg font-semibold"
            leftIcon={<MicIcon className="w-6 h-6" />}
            onClick={handleClickOpen}
          />
        </div>
      </div>

      {/* Uploaded File Progress (if a file is chosen) */}
      {uploadedFile && (
        <div className="relative flex flex-col items-left rounded-2xl p-3 m-6 bg-[var(--color-white)]">
          <button
            type="button"
            className="absolute top-3 right-3 p-1 bg-grey2 rounded-full"
            onClick={handleCancelUpload}
          >
            <XMarkIcon
              className="h-4 w-4 text-[var(--color-primary)]"
              aria-hidden="true"
              strokeWidth={2}
            />
          </button>
          <div className="flex items-center">
            <Mp3Icon className="mr-4" />
            <div>
              <span className="text-[var(--color-text1)] text-lg">
                {uploadedFile?.name}
              </span>
            </div>
          </div>

          {isUploading && (
            <div className="flex items-center mt-2 ml-2 space-x-4">
              {/* Show uploading details */}
              <div className="flex items-center">
                <CircularProgress size={20} className="mr-2 text-blue" />
                <span className="text-grey text-sm">
                  Uploading... {((fileSize * uploadProgress) / 100).toFixed(2)}
                  MB / {fileSize}MB
                </span>
              </div>

              {/* Progress bar */}
              <div className="w-3/4">
                <LinearProgress
                  variant="determinate"
                  value={uploadProgress}
                  sx={{
                    height: "8px",
                    borderRadius: "12px",
                    backgroundColor:
                      theme.palette.mode === "light" ? "#D9D9D9" : "#2A2D3E",
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: "#375EF9",
                      borderRadius: "10px",
                    },
                  }}
                />
              </div>
            </div>
          )}
        </div>
      )}

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-paper": { backgroundColor: "#373737", width: "450px" },
          "& .MuiDialogContent-dividers": {
            borderColor: "#B9B9B9",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, color: "#FFFFFF" }}
          id="customized-dialog-title"
        >
          Recording
        </DialogTitle>
        <button
          onClick={handleClose}
          type="button"
          className="absolute top-0 right-0 m-4 p-1 rounded-full"
          style={{ backgroundColor: "#FFFFFF1A" }}
        >
          <XMarkIcon
            className="h-5 w-5 text-white"
            aria-hidden="true"
            strokeWidth={2}
          />
        </button>
        <DialogContent dividers>
          <div className="flex flex-col w-full items-center justify-center">
            <VoiceVisualizer
              controls={recorderControls}
              mainBarColor="#FF4C51"
              secondaryBarColor="transparent"
              backgroundColor="transparent"
              barWidth={8}
              width={400}
              height={100}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <div className="flex justify-between items-center w-full p-2 px-4">
            <button
              type="button"
              className="bg-white p-2 px-10 medium-text font-semibold text-dark-grey rounded-lg"
              onClick={handleClose}
            >
              Reset
            </button>
            <button
              type="button"
              className="bg-blue p-2 px-10 medium-text font-semibold text-white rounded-lg"
              onClick={handleOpenDialog}
            >
              Save
            </button>
          </div>
        </DialogActions>
      </Dialog>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        textBox={true}
        showRightIcon={false}
        onConfirm={handleConfirm}
        onAudioNameChange={setAudioName}
      />
    </div>
  );
};

export default UploadAudioStep;
