import React from "react";
import { useTheme } from "@mui/material/styles";
import Button from "@mui/material/Button";

const CancelButton = ({ type, label, className, leftIcon, onClick }) => {
  const theme = useTheme();

  return (
    <Button
      type={type}
      onClick={onClick}
      variant="outlined"
      color="#A5A5A5"
      className={`${className}`}
      sx={{
        borderRadius: "8px",
        textTransform: "none",
        fontWeight: "500",
        fontSize: {
          md: "14px",
          xxl: "16px",
          // xxl: "18px",
        },
        paddingY: "10px",
        color:
            theme.palette.mode === "dark" ? "#B9B9B9" : "#5F6177",
        // width: "130px",
      }}
    >
      {leftIcon && <span className="mr-2">{leftIcon}</span>}
      {label}
    </Button>
  );
};

export default CancelButton;
