import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import { ReactComponent as EditIcon } from "../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as UserPlus } from "../assets/UserPlus.svg";
import CommonDialog from "../shared/CommonDialog";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "../shared/TextInput";
import Dropdown from "../shared/DropDown";
import PrimaryButton from "../shared/PrimaryButton";
import { createUser } from "../services/userService";
import { getUsers } from "../services/userService";
import { deleteUser } from "../services/userService";
import CircularProgress from "@mui/material/CircularProgress";
import femaleImg from "../assets/female-avatar.svg";
import maleImg from "../assets/male-avatar.svg";
import { format } from "date-fns";

const UserManagementPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage] = useState(10);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [showRightIcon, setShowRightIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role: "",
    contactNumber: "",
  });
  const [isFormValid, setIsFormValid] = useState(false);

  const roleOptions = [
    { value: "Radiologist", label: "Radiologist" },
    { value: "Lab admin", label: "Lab Admin" },
  ];
  const [selectedUserEmail, setSelectedUserEmail] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dialogType, setDialogType] = useState("");

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    contactNumber: "",
    role: "",
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery) ||
      user.email.toLowerCase().includes(searchQuery) ||
      (user.contact_no || "").toLowerCase().includes(searchQuery)
  );

  // Fetch users on component mount
  const fetchUsers = async (delay = 0) => {
    setIsLoading(true);
    try {
      if (delay > 0) {
        await new Promise((resolve) => setTimeout(resolve, delay)); // Introduce delay
      }
      const response = await getUsers(); // Call the getUsers API
      setUsers(response); // Assume response.data contains the array of users
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // Fetch users on component mount
  useEffect(() => {
    // Check if returning from edit users page
    const state = window.history.state;
    if (state.usr && state.usr.fromEditUserPage) {
      fetchUsers(1000); // Call with 1-second delay
    } else {
      fetchUsers(); // Normal fetch without delay
    }
  }, []);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => {
      const updatedFormData = { ...prevState, [id]: value };
      validateForm(updatedFormData); // Validate the updated data
      return updatedFormData;
    });
  };

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    const updatedFormData = { ...formData, role: role.value };
    setFormData(updatedFormData);
    validateForm(updatedFormData);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const totalPages = Math.max(1, Math.ceil((users?.length || 0) / rowsPerPage));

  const handleOpenDialog1 = (user) => {
    setSelectedUserEmail(user.email);
    setDialogType("delete");
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Sure You Want To Delete Account?");
    setDialogContent(
      "The account would be deleted permanently. Are you sure you want to proceed?"
    );
    setButtonLabel("Yes");
    setDialogOpen(true);
    setShowRightIcon(false);
    setShowDeleteIcon(true);
  };

  const handleOpenDialog2 = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;

    try {
      const payload = {
        name: formData.name,
        email: formData.email,
        role: formData.role,
        contact_no: formData.contactNumber,
      };
      await createUser(payload);
      // Set the dialog title and content dynamically based on form data
      setDialogTitle("Account Created Successfully!");
      setButtonLabel("Okay");
      setDialogContent("New account has been created successfully.");
      setDialogOpen(true);
      setShowRightIcon(true);
      setShowDeleteIcon(false);
      setOpen(false);
      setFormData({
        name: "",
        email: "",
        role: "",
        contactNumber: "",
      });
      setSelectedRole(null);
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };

  const validateForm = (data) => {
    let nameError = "";
    let emailError = "";
    let contactError = "";
    let roleError = "";

    if (!data.name.trim()) {
      nameError = "Name is required and should only contain alphabets.";
    } else if (!/^[A-Za-z\s]+$/.test(data.name)) {
      nameError = "Name should only contain alphabets.";
    }

    if (!data.email.trim()) {
      emailError = "Email is required.";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
      emailError = "Please enter a valid email address.";
    }

    if (!data.contactNumber.trim()) {
      contactError = "Contact number is required.";
    } else if (!/^[0-9]{10}$/.test(data.contactNumber)) {
      contactError = "Contact number must be 10 digits.";
    }

    if (!data.role) {
      roleError = "Role is required.";
    }

    setErrors({
      name: nameError,
      email: emailError,
      contactNumber: contactError,
      role: roleError,
    });

    setIsFormValid(!nameError && !emailError && !contactError && !roleError);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    if (dialogType === "delete") {
      try {
        // Pass the email to deleteUser API
        await deleteUser(selectedUserEmail);
        // Optionally, refresh the user list or remove the deleted user from the list
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user.email !== selectedUserEmail)
        );
      } catch (error) {
        console.error("Error deleting user:", error);
      }
    }
    setDialogOpen(false);
    setDialogType("");
    fetchUsers();
  };

  const handleRowClick = (user) => {
    navigate(`/user-details/${user.id}`, { state: { user } });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy");
  };

  return (
    <div className="p-6">
      <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 justify-between md:items-center">
        <div className="flex xxs:w-full md:w-1/3">
          <TextField
            variant="outlined"
            placeholder="Search name..."
            value={searchQuery}
            onChange={handleSearchChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #C9CBCD",
                  borderRadius: "8px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #5F6177",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#11171999" : "grey",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "white",
                borderRadius: "8px",
                height: "45px",
                fontSize: "14px",
              },
            }}
            className="w-full"
          />
          <button className="ml-4 p-2.5 rounded-lg bg-primary">
            <SearchIcon sx={{ color: "white" }} />
          </button>
        </div>
        <div>
          <button
            className="flex bg-primary p-2 px-4 medium-text font-semibold text-white rounded-lg"
            onClick={handleClickOpen}
          >
            <UserPlus className="w-6 h-6 mr-2" />
            Add User
          </button>
        </div>
      </div>
      <div className="mt-6 rounded-2xl">
        {isLoading ? ( // Show spinner while loading
          <div className="flex justify-center items-center h-64">
            <CircularProgress />
          </div>
        ) : (
          <TableContainer
            sx={{
              backgroundColor:
                theme.palette.mode === "light" ? "white" : "#1A1A1A",
              paddingTop: "15px",
              borderTopLeftRadius: "16px",
              borderTopRightRadius: "16px",
            }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>USER ID</TableCell>
                  <TableCell>USER NAME</TableCell>
                  <TableCell>ROLE</TableCell>
                  <TableCell>DOJ</TableCell>
                  <TableCell>REPORTS</TableCell>
                  <TableCell>ACTION</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No users available.
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user, index) => (
                      <TableRow
                        key={user.id}
                        onClick={() => handleRowClick(user)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell
                          sx={{
                            fontWeight: "500",
                            fontSize: { xxs: "14px", md: "16px" },
                          }}
                        >
                          {user.id}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontWeight: "500",
                            fontSize: { xxs: "14px", md: "16px" },
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Avatar
                            src={
                              user.profile_photo
                                ? user.profile_photo // If the user has an avatar, use it
                                : user.gender === "Female" // If gender is female, use a default female avatar
                                ? femaleImg
                                : maleImg // Otherwise, use a default male avatar
                            }
                            alt={user.name}
                            className="mr-2"
                            sx={{
                              boxShadow: "0px 4px 8px 0px #00000029",
                              borderWidth: "3px",
                              borderColor: "white",
                              width: "45px",
                              height: "45px",
                            }}
                          />
                          {user.name}
                        </TableCell>
                        <TableCell>{user.role}</TableCell>
                        <TableCell>{user.created_on ? formatDate(user.created_on) : "-"}</TableCell>
                        <TableCell>{user.reports || "-"}</TableCell>
                        <TableCell
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent row click event
                          }}
                        >
                          <div className="flex space-x-4">
                            <button
                              type="button"
                              className="flex bg-blue p-1.5 px-3 text-sm font-semibold text-white rounded-lg"
                              onClick={() =>
                                navigate(`/edit-user/${user.id}`, {
                                  state: { user },
                                })
                              }
                            >
                              <EditIcon className="mt-0.5 mr-2" />
                              Edit
                            </button>
                            <button
                              type="button"
                              className="flex bg-error p-1.5 px-3 text-sm font-semibold text-white rounded-lg"
                              onClick={() => handleOpenDialog1(user)}
                            >
                              <DeleteIcon className="mt-0.5 mr-2 text-white" />
                              Delete
                            </button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <div className="flex items-center justify-between bg-[var(--color-white)] mt-4 py-4 px-4">
          <span className="text-[var(--color-dark-grey)] text-sm font-medium">
            Showing {page * rowsPerPage + 1} to{" "}
            {users?.length > 0
              ? Math.min((page + 1) * rowsPerPage, users.length)
              : 0}{" "}
            of {users?.length || 0} entries
          </span>
          <Pagination
            count={totalPages || 1}
            page={Math.min(page + 1, totalPages || 1)}
            onChange={handlePageChange}
            siblingCount={1}
            sx={{
              "& .MuiPaginationItem-root": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode background
                    : "#2A2D3E", // Dark mode background
                borderRadius: "4px",
                color:
                  theme.palette.mode === "light"
                    ? "#5F6177" // Light mode text color
                    : "#A1A1B1", // Dark mode text color
                fontWeight: "600",
                width: "36px", // Set a fixed width for consistency
                height: "36px", // Set a fixed height for consistency
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "4px 4px",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#513ADC",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#513ADC" // Light mode selected hover
                      : "#836FFF", // Dark mode selected hover
                },
              },
              "& .MuiPaginationItem-root.Mui-disabled": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode disabled background
                    : "#1E1E2F", // Dark mode disabled background
                color:
                  theme.palette.mode === "light"
                    ? "#B9B9B9" // Light mode disabled text
                    : "#5F6177", // Dark mode disabled text
              },
            }}
          />
        </div>
      </div>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDeleteIcon={showDeleteIcon}
        showRightIcon={showRightIcon}
        onConfirm={handleConfirm}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            borderRadius: "10px",
            padding: "5px",
            width: "500px",
          },
        }}
      >
        <DialogTitle
          sx={{ m: 0, pb: 0, color: "#333333" }}
          id="customized-dialog-title"
        >
          Create Account
        </DialogTitle>
        <button
          onClick={handleClose}
          type="button"
          className="absolute top-0 right-0 m-4 p-1 bg-grey2 rounded-full"
        >
          <XMarkIcon
            className="h-5 w-5"
            strokeWidth={2}
            style={{ color: "#4F4F4F" }}
          />
        </button>

        <DialogContent>
          <div className="flex flex-col space-y-6 p-3 pt-0 w-full">
            <TextInput
              id="name"
              type="text"
              label="Name"
              placeholder="Enter User Name"
              value={formData.name}
              onChange={handleInputChange}
              required={true}
              error={!!errors.name}
              helperText={errors.name}
            />

            <TextInput
              id="email"
              type="text"
              label="Email"
              placeholder="Enter Email Id"
              value={formData.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
              required={true}
            />

            <div>
              <Dropdown
                id="role"
                label="Role"
                showLabel={true}
                options={roleOptions}
                selectedOption={selectedRole}
                onChange={handleRoleChange}
                required={true}
              />
              {errors.role && (
                <p className="text-error text-xs mt-1 ml-3">{errors.role}</p>
              )}
            </div>

            <TextInput
              id="contactNumber"
              type="text"
              label="Contact Number"
              placeholder="Enter Contact Number"
              value={formData.contactNumber}
              onChange={handleInputChange}
              error={!!errors.contactNumber}
              helperText={errors.contactNumber}
              required={true}
            />

            <PrimaryButton
              type="submit"
              label="Create Account"
              disabled={
                !formData.name.trim() ||
                !formData.email.trim() ||
                !formData.contactNumber.trim() ||
                !formData.role.trim()
              }
              className="w-full"
              onClick={handleOpenDialog2}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default UserManagementPage;
