import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { LinearProgress } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { plans } from "../../constants/planData";
import PlanCard from "../subscription/PlanCard";
import PlanDescription from "../subscription/PlanDescription";

function BillingPlans() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPlanId(null); // Reset the selected plan on dialog close
  };

  const handleChoosePlan = (planId) => {
    setSelectedPlanId(planId); // Set the selected plan ID
  };

  const handleBackToPlans = () => {
    setSelectedPlanId(null); // Reset to show the list of plans
  };

  return (
    <div className="flex xxs:flex-col md:flex-row m-6 bg-[var(--color-white)] p-8 rounded-[10px] xxs:gap-y-6 md:gap-y-0">
      <div className="xxs:w-full md:w-1/2">
        <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">Current Plan</p>

        <p className="mt-4 mb-0.5 medium-text text-[var(--color-text1)]">
          Your current plan is advanced
        </p>
        <span className="text-[var(--color-dark-grey)] small-text">
          A simple start for everyone
        </span>

        <div className="flex items-center mt-4 mb-0.5">
          <p className="xxs:text-sm md:text-lg text-[var(--color-text1)] mr-2">
            Active until Dec 09, 2024
          </p>
          <Chip
            label="Popular"
            sx={{ color: theme.palette.mode === "dark" ? "#6754CC" : "#513ADC", backgroundColor: theme.palette.mode === "dark" ? "#2A2D3E" : "#EFD5FF66" }}
          />
        </div>
        <p className="text-[var(--color-dark-grey)] small-text">
          We will send you a notification upon Subscription expiration
        </p>

        <p className="mt-4 mb-0.5 medium-text text-[var(--color-text1)]">$199 Per Month</p>
        <span className="text-[var(--color-dark-grey)] small-text">
          Standard plan for small to medium businesses
        </span>

        <div className="mt-5 space-x-4">
          <button
            type="button"
            className="bg-primary text-white rounded-md py-1.5 px-2.5"
            onClick={handleClickOpen}
          >
            Upgrade Plan
          </button>
          <button
            type="button"
            className="bg-[#DC443A21] text-[#FF4C51] rounded-md py-1.5 px-2.5"
          >
            Cancel Subscription
          </button>
        </div>
      </div>

      <div className="flex flex-col xxs:w-full md:w-1/2">
        <div className="flex space-x-4 bg-[#FC66261A] rounded-md p-4 w-full">
          <div className="flex w-min h-min items-center bg-[#FF9500] p-1 rounded-[5px]">
            <WarningAmberRoundedIcon
              sx={{ width: 25, height: 25, color: "white" }}
            />
          </div>
          <div className="flex flex-col">
            <span className="medium-text text-[#FF9500]">
              We need your attention!
            </span>
            <span className="text-[#FF9500] text-xs">
              Your plan requires update
            </span>
          </div>
        </div>

        <div className="flex justify-between mt-4 mb-1">
          <span className="text-sm">Days</span>
          <span className="text-sm">12 of 30 days</span>
        </div>
        <LinearProgress
          variant="determinate"
          value={40}
          sx={{
            height: "10px",
            borderRadius: "12px",
            backgroundColor: theme.palette.mode === "light" ? '#D9D9D9': '#2A2D3E',
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#513ADC",
              borderRadius: "12px",
            },
          }}
        />
        <span className="text-[var(--color-dark-grey)] text-[10px] mt-1">
          18 days remaining until your plan requires update
        </span>
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            borderRadius: "10px",
            padding: "5px",
            width: "90%", // Set width to 90%
            maxWidth: "90%", // Ensure it doesn't shrink
          },
        }}
        sx={{
          "& .MuiDialog-paper": {
            width: "90%", // Set width to 90%
            maxWidth: "90%", // Prevent it from exceeding this size
          },
        }}
      >
        {!selectedPlanId && (
          <DialogTitle
            sx={{
              m: 0,
              pt: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            id="customized-dialog-title"
          >
            <span className="text-[var(--color-text1)] xxs:large-text md:text-[38px] font-normal">
              Pricing Plans
            </span>
            <span className="text-[var(--color-dark-grey)] font-semibold xxs:text-sm md:text-lg">
              All plans include 40+ advanced tools and features to boost your
              product. Choose the best plan to fit your needs.
            </span>
          </DialogTitle>
        )}
        {!selectedPlanId && (
          <button
            onClick={handleClose}
            type="button"
            className="absolute top-0 right-0 m-4 p-1 bg-grey2 rounded-full"
          >
            <XMarkIcon
              className="h-5 w-5 text-[#4F4F4F]"
              strokeWidth={2.5}
            />
          </button>
        )}

        <DialogContent>
          {!selectedPlanId ? (
            <div className="flex xxs:flex-col xxs:items-center sm:flex-row sm:items-end xxs:m-4 gap-4 md:m-0">
              {plans.map((plan) => (
                <PlanCard
                  key={plan.id}
                  planId={plan.id}
                  title={plan.title}
                  price={plan.price}
                  features={plan.features}
                  onChoose={() => handleChoosePlan(plan.id)}
                />
              ))}
            </div>
          ) : (
            <PlanDescription
              planID={selectedPlanId}
              onBack={handleBackToPlans}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default BillingPlans;
