import React, { useState } from "react";
import { useParams } from "react-router-dom";
import TextInput from "../../shared/TextInput";
import PrimaryButton from "../../shared/PrimaryButton";
import Divider from "@mui/material/Divider";
import { ReactComponent as Visa } from "../../assets/Visa.svg";
import { ReactComponent as GooglePay } from "../../assets/GooglePay.svg";
import CommonDialog from "../../shared/CommonDialog";
import { useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import { subscribePlan } from "../../services/subscriptionService";

const CheckoutPage = () => {
  const navigate = useNavigate();
  const { planId } = useParams();
  const [paymentMethod, setPaymentMethod] = useState("credit-card");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");

  const handleOpenDialog = () => {
    setDialogTitle("Payment Successfull!");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Back to Login");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Navigate to login page when the confirm button is clicked
    navigate("/login");
  };

  const handlePayment = async () => {
    try {
      // Call the subscribePlan API to handle subscription
      await subscribePlan(planId); // Pass planId to subscribePlan
      handleOpenDialog();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="bg-[var(--color-white)] rounded-lg w-full table">
      <div className="flex xxs:flex-col sm:flex-row gap-8">
        {/* Left column: Payment and billing details */}
        <div className="padding-responsive">
          <p className="large-text font-medium text-[var(--color-text1)] mb-4">
            Checkout
          </p>
          <p className="text-blue-text medium-text mb-8">
            All plans include 40+ advanced tools and features to boost your
            product. Choose the best plan to fit your needs.
          </p>

          {/* Payment Method */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
            <div
              className={`border rounded-lg p-4 flex cursor-pointer ${
                paymentMethod === "credit-card"
                  ? "border-purple"
                  : "border-light-grey"
              }`}
              onClick={() => setPaymentMethod("credit-card")}
            >
              <Radio
                checked={paymentMethod === "credit-card"}
                onChange={() => setPaymentMethod("credit-card")}
                sx={{
                  color: "#B9B9B9",
                  "&.Mui-checked": {
                    color: "#513adc",
                  },
                }}
                value="credit-card"
                name="payment-method"
              />
              <label className="ml-4 flex items-center">
                <Visa className="mr-2" />
                Credit Card
              </label>
            </div>
            <div
              className={`border rounded-lg p-4 flex cursor-pointer ${
                paymentMethod === "google-pay"
                  ? "border-purple"
                  : "border-light-grey"
              }`}
              onClick={() => setPaymentMethod("google-pay")}
            >
              <Radio
                checked={paymentMethod === "google-pay"}
                onChange={() => setPaymentMethod("google-pay")}
                sx={{
                  color: "#B9B9B9",
                  "&.Mui-checked": {
                    color: "#513adc",
                  },
                }}
                value="google-pay"
                name="payment-method"
              />
              <label className="ml-4 flex items-center">
                <GooglePay className="mr-2" />
                Google Pay
              </label>
            </div>
          </div>

          {/* Billing Details */}
          <div>
            <h3 className="large-text font-medium text-[var(--color-text1)] mb-4">
              Billing Details
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
              <TextInput
                id="email"
                type="email"
                label="Email"
                placeholder="Enter Email Id"
              />
              <TextInput
                id="password"
                type="password"
                label="Password"
                placeholder="Password"
              />
              <TextInput
                id="country"
                type="text"
                label="Country"
                placeholder="Country"
              />
              <TextInput
                id="zip"
                type="text"
                label="Zip/Postal Code"
                placeholder="Zip/Postal Code"
              />
            </div>
          </div>

          {/* Credit Card Info */}
          <div>
            <h3 className="large-text font-medium text-[var(--color-text1)] mb-4">
              Credit Card Info
            </h3>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <TextInput
                id="cardNumber"
                type="text"
                label="Card Number"
                placeholder="Card Number"
              />
              <TextInput
                id="name"
                type="text"
                label="Name"
                placeholder="Name"
              />
              <TextInput
                id="expiry"
                type="text"
                label="MM/YY"
                placeholder="MM/YY"
              />
              <TextInput id="cvv" type="text" label="CVV" placeholder="CVV" />
            </div>
          </div>
        </div>

        {/* Vertical divider */}
        <Divider orientation="vertical" flexItem />

        {/* Right column: Order summary */}
        <div className="padding-responsive">
          <h3 className="large-text font-medium text-[var(--color-text1)] mb-4">
            Order Summary
          </h3>
          <div className="bg-pink-light2 p-4 rounded-2xl mb-6">
            <p className="medium-text text-[var(--color-dark-grey)] mb-2">
              A simple start for everyone
            </p>
            <p className="mt-6">
              <span className="extra-large-text text-[var(--color-text1)]">
                ${planId === "premium" ? "59.90" : "29.90"}
              </span>
              <span className="text-[var(--color-dark-grey)] medium-text">
                /month
              </span>
            </p>
            <button
              className="rounded-lg w-full mt-8 px-4 py-2"
              style={{ background: "#B9A3DE33", color: "#8954A9" }}
              onClick={() => navigate("/subscription/plans")}
            >
              Change Plan
            </button>
          </div>

          <div className="flex justify-between medium-text text-text-1 mb-4">
            <span>Subtotal</span>
            <span>${planId === "premium" ? "74.90" : "34.90"}</span>
          </div>
          <div className="flex justify-between medium-text text-text-1 mb-4">
            <span>Tax</span>
            <span>$4.30</span>
          </div>
          <Divider orientation="horizontal" flexItem />
          <div className="flex justify-between medium-text text-text-1 mt-4 mb-10">
            <span>Total</span>
            <span>${planId === "premium" ? "79.20" : "39.20"}</span>
          </div>

          <PrimaryButton
            label="Proceed To Payment"
            className="w-full"
            onClick={handlePayment}
          />

          <p className="text-blue-text small-text mt-6">
            By continuing, you accept our Terms of Services and Privacy Policy.
            Please note that payments are non-refundable.
          </p>
        </div>
      </div>
      {/* Pass the dynamic title and content to CommonDialog */}
      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showRightIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default CheckoutPage;
