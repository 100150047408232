import React from "react";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { ReactComponent as MicIcon } from "../../assets/mic.svg";
import Divider from "@mui/material/Divider";

const ReportFileType = () => {
  return (
    <div
      className="bg-[var(--color-white)] p-4 rounded-[10px] w-full h-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      {/* Header and Dropdown */}
      <div className="mb-4 p-2">
        <span className="text-lg font-medium text-blue-text">
          Report File Type
        </span>
        <p className="text-xs text-grey">
          Overall report created overview by category
        </p>
      </div>

      {/* Icons for file upload and mic */}
      <div className="grid grid-cols-9 space-x-4 p-1 md:mt-14 xxs:mt-6">
        <div className="col-span-4 space-y-8">
          <div className="flex items-center space-x-2">
            <div className="space-x-2 p-2 rounded-lg bg-error-light dark:bg-white-light">
              <UploadFileOutlinedIcon sx={{ color: "#FF4C51" }} />
            </div>
            <span className="medium-text text-blue-text">File Uploaded</span>
          </div>
          <div>
            <p className="text-[var(--color-dark-grey)] text-lg">56.2%</p>
            <p className="text-blue-text medium-sm">156</p>
          </div>
        </div>
        <div className="col-span-1 flex justify-center">
          <Divider orientation="vertical" variant="middle">
            <span className="bg-primary-light2 dark:bg-white-light px-2 py-2.5 rounded-full text-[var(--color-primary)] text-xs font-medium">
              vs
            </span>
          </Divider>
        </div>
        <div className="col-span-4 space-y-8">
          <div className="flex items-center space-x-2">
            <span className="medium-text text-blue-text">Recorded Voice</span>
            <div className="flex items-center space-x-2 p-2 rounded-lg bg-[#F8D6F066] dark:bg-white-light">
              <MicIcon className="w-5 h-5 text-purple" />
            </div>
          </div>
          <div className="text-end">
            <p className="text-[var(--color-dark-grey)] text-lg">23.04%</p>
            <p className="text-blue-text medium-sm">44</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportFileType;
