import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { plans } from "../../constants/planData"; // Adjust the path as necessary
import CancelButton from "../../shared/CancelButton";
import PrimaryButton from "../../shared/PrimaryButton";
import CircleIcon from '@mui/icons-material/Circle';

const PlanDescription = ({ planID, onBack }) => {
  const { planId } = useParams();
  const navigate = useNavigate();

  // Find the plan based on the planId
  const plan = plans.find((p) => p.id === planId || p.id === planID);

  const handleContinue = () => {
    navigate(`/subscription/checkout/${planId}`);
  };

  return (
    <div className={`${planId ? "padding-responsive" : "p-0"} bg-white dark:bg-transparent rounded-lg w-full table`}>
      <p className="extra-large-text text-[var(--color-text1)]">{plan.title} Plan Description</p>
      <p className="mt-8 xxs:text-sm sm:text-lg text-[var(--color-dark-grey)]">{plan.description}</p>

      <h3 className="mt-8 text-xl text-[var(--color-text1)]">Key Features:-</h3>
      <ul className="mt-8 space-y-4 text-[var(--color-dark-grey)]">
        {plan.detailedFeatures.map((feature, index) => (
          <li key={index} className="flex items-start">
            <CircleIcon
              sx={{
                color: "#5F6177",
                fontSize: {
                  xxs: "5px",
                  md: "6px",
                },
                mx: 1,
                mt: "-0.2rem",
              }}
            />
            <span className="xxs:text-sm sm:text-lg -mt-3">{feature.name}</span>
          </li>
        ))}
      </ul>

      <div className="xxs:mt-6 sm:mt-10 flex space-x-4">
        <CancelButton
          type="button"
          label="Cancel"
          className="w-1/2"
          onClick={planID ? onBack : () => navigate(-1)}
        />
        <PrimaryButton
          type="submit"
          label="Continue"
          className="w-1/2"
          onClick={handleContinue}
        />
      </div>
    </div>
  );
};

export default PlanDescription;
