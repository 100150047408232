import React from "react";
import { Dialog } from "@headlessui/react";
import PrimaryButton from "./PrimaryButton";
import CancelButton from "./CancelButton";

const ConfirmDialog = ({ open, onClose, title, content, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
          <Dialog.Panel className="relative bg-[var(--color-white)] rounded-lg text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
            {/* Main content */}
            <div className="p-8">
              <div className="mb-4 text-center">
                {/* Render dynamic title */}
                <Dialog.Title
                  as="h3"
                  className="text-xl font-medium text-[#54595E] dark:text-text1-dark"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-2">
                  {/* Render dynamic content */}
                  <p className="text-sm text-[#54595E99] dark:text-blue-text">
                    {content}
                  </p>
                </div>
              </div>

              <div className="flex justify-between mt-6 space-x-6">
                {/* No button */}
                <CancelButton
                  type="button"
                  label="No, Cancel"
                  className="w-full"
                  onClick={() => {
                    onClose();
                  }}
                />

                {/* Yes button */}
                <PrimaryButton
                  type="button"
                  label="Yes, Confirm"
                  className="w-full"
                  onClick={() => {
                    onConfirm();
                    onClose();
                  }}
                />
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDialog;
