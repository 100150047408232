import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

export const subscribePlan = async (planId) => {
  try {
    const response = await apiClient.post("subscription/subscribe", { plan_id: planId });
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.message || "Subscription failed!"); // Error toast
    throw error.response?.data;
  }
};
