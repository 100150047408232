import React from "react";
import { useTheme } from '@mui/material/styles';
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { ReactComponent as OtherUser } from "../assets/profile-2user.svg";
import { LinearProgress } from "@mui/material";
import { Progress } from "rsuite";

function ReportsCard({ count, label, progressColor, progressValue, stats }) {
  const theme = useTheme();
  const weekData = [
    { week: "Mon" },
    { week: "Tue" },
    { week: "Wed" },
    { week: "Thu" },
    { week: "Fri", value: progressValue },
    { week: "Sat" },
  ];

  return (
    <div
      className="bg-[var(--color-white)] rounded-[10px] p-5 flex flex-col w-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      <div className="flex flex-col">
        <p className="xxs:text-base md:text-[20px] text-[var(--color-text1)]">
          {label} Reports
        </p>
        <span className="xxs:text-xs md:text-sm text-blue-text">
          Weekly Report Overview
        </span>
      </div>
      <div className="flex items-end">
        <div className="flex flex-col">
          <div className="flex items-end">
            <span className="xxs:text-xl md:text-4xl font-medium text-[var(--color-text1)]">
              {count}
            </span>
            <span className="xxs:text-sm md:text-base text-blue-text ml-2 mb-1">
              {label}
            </span>
          </div>
          <span className="xxs:text-xs md:text-sm text-blue-text w-2/3">
            You informed this week compared to last week
          </span>
        </div>
        <div className="flex items-end">
          {weekData.map((data, index) => (
            <div key={index} className="flex flex-col items-center">
              <Progress.Line
                vertical
                percent={data.value}
                showInfo={false}
                strokeColor={progressColor} // Customize color if needed
                style={{ height: "100px" }}
              />
              <span className="text-xs text-[var(--color-dark-grey)]">
                {data.week}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 flex flex-wrap gap-4 justify-between text-sm border border-light-grey rounded-[10px] p-4">
        <div className="flex items-center space-x-1">
          <div className="bg-[#63D9FF33] rounded-[10px] flex items-center justify-center p-1">
            <MaleIcon style={{ color: "#00BAD1", fontSize: "30px" }} />
          </div>
          <div>
            <span className="medium-text text-[var(--color-text1)]">Male</span>
            <LinearProgress
              variant="determinate"
              value={stats.male}
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00BAD1",
                  borderRadius: 10,
                },
                height: 4,
                width: 58,
                borderRadius: 10,
                backgroundColor: theme.palette.mode === "light" ? "#B9B9B9" : "#2A2D3E",
              }}
            />
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="bg-[#FF9D8833] rounded-[10px] flex items-center justify-center p-1">
            <FemaleIcon style={{ color: "#FF4C51", fontSize: "30px" }} />
          </div>
          <div>
            <span className="medium-text text-[var(--color-text1)]">Female</span>
            <LinearProgress
              variant="determinate"
              value={stats.female}
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#FF4C51",
                  borderRadius: 10,
                },
                height: 4,
                width: 58,
                borderRadius: 10,
                backgroundColor: theme.palette.mode === "light" ? "#B9B9B9" : "#2A2D3E",
              }}
            />
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="bg-[#45FF4C33] rounded-[10px] flex items-center justify-center p-2">
            <OtherUser className="h-6 w-6" />
          </div>
          <div>
            <span className="medium-text text-[var(--color-text1)]">Others</span>
            <LinearProgress
              variant="determinate"
              value={stats.others}
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#28C76F",
                  borderRadius: 10,
                },
                height: 4,
                width: 58,
                borderRadius: 10,
                backgroundColor: theme.palette.mode === "light" ? "#B9B9B9" : "#2A2D3E",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsCard;
