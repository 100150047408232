import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

i18next.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        "navbar": {
          "notifications": "Notifications",
          "logoutConfirmation": "Are you sure you want to log out?",
          "logoutContent": "Are you sure you want to log out?"
        },
        "notifications": {
          "mark_all_as_read": "Mark all as read",
          "unread": "Unread ({{count}})",
          "reportSubmitted": "Report submitted successfully.",
          "reportSaved": "Report saved as a draft.",
          "viewAll": "View all",
          "time_ago": "{{time}} ago" // Generalized for dynamic usage
        }
      }
    },
    fr: {
      translation: {
        "navbar": {
          "notifications": "Notifications",
          "logoutConfirmation": "Êtes-vous sûr de vouloir vous déconnecter?",
          "logoutContent": "Êtes-vous sûr de vouloir vous déconnecter?"
        },
        "notifications": {
          "mark_all_as_read": "Tout marquer comme lu",
          "unread": "Non lu ({{count}})",
          "reportSubmitted": "Rapport soumis avec succès.",
          "reportSaved": "Rapport enregistré en tant que brouillon.",
          "viewAll": "Voir tout",
          "time_ago": "Il y a {{time}}" // Generalized for dynamic usage
        }
      }
    },
    en_GB: { // British English translations
      translation: {
        "navbar": {
          "notifications": "Notifications",
          "logoutConfirmation": "Are you sure you want to log out?",
          "logoutContent": "Are you sure you want to log out?"
        },
        "notifications": {
          "mark_all_as_read": "Mark all as read",
          "unread": "Unread ({{count}})",
          "reportSubmitted": "Report submitted successfully.",
          "reportSaved": "Report saved as a draft.",
          "viewAll": "View all",
          "time_ago": "{{time}} ago" // Generalized for dynamic usage
        }
      }
    },
    // Add more languages here
  },
  lng: "en", // Default language
  fallbackLng: "en",
  interpolation: {
    escapeValue: false // React already does escaping
  }
});

export default i18next;
