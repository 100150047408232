import React, { useState } from "react";
import TextInput from "../../shared/TextInput";
import PrimaryButton from "../../shared/PrimaryButton";
import { ReactComponent as ClappingHands1 } from "../../assets/noto_clapping-hands (1).svg";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import CommonDialog from "../../shared/CommonDialog";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../services/authService";

const RegisterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    labName: "",
    labLocation: "",
    password: "",
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Account Created Successfully!");
    setDialogContent(
      "Your account has been successfully created. Please log in to continue."
    );
    setButtonLabel("Back to Login");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Add your confirm logic here
    navigate("/login");
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
        lab_name: formData.labName, // Change this key
        lab_location: formData.labLocation, // Change this key
      };
      console.log(payload);
      const response = await registerUser(payload);
      console.log("User registered successfully:", response);
      handleOpenDialog(); // Open success dialog
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };

  return (
    <div
      className="bg-[var(--color-white)] rounded-[20px] padding-responsive"
      style={{ boxShadow: "0px 9px 34px 0px #0000001A" }}
    >
      <form onSubmit={handleSubmit} className="px-2">
        <div className="flex items-center">
          <span className="pr-2 mb-1 text-[var(--color-dark-grey)] font-heading large-text font-semibold">
            Welcome to Emilio Muse!
          </span>
          <ClappingHands1 className="-mt-2" />
        </div>
        <div className="text-dark-grey small-text mb-6">
          Please enter name, email id, lab name, location and role to create an
          account
        </div>

        <div className="flex flex-col gap-5">
          <TextInput
            id="name"
            type="text"
            label="Name"
            placeholder="Enter User Name"
            value={formData.name}
            onChange={handleInputChange}
          />

          <TextInput
            id="email"
            type="email"
            label="Email"
            placeholder="Enter Email Id"
            value={formData.email}
            onChange={handleInputChange}
          />

          <TextInput
            id="labName"
            type="text"
            label="Lab Name"
            placeholder="Enter Lab Name"
            value={formData.labName}
            onChange={handleInputChange}
          />

          <TextInput
            id="labLocation"
            type="text"
            label="Lab Location"
            placeholder="Enter Lab Location"
            value={formData.labLocation}
            onChange={handleInputChange}
          />

          <TextInput
            id="password"
            type="password"
            label="Password"
            placeholder="Enter Password"
            value={formData.password}
            onChange={handleInputChange}
          />

          <div className="mt-4">
            <PrimaryButton
              type="submit"
              label="Create Account"
              className="w-full"
              onClick={handleSubmit}
            />
          </div>
        </div>

        <div className="mt-8 text-center medium-text flex items-center justify-center">
          <button
            type="button"
            className="text-blue text-sm flex items-center"
            onClick={() => navigate("/login")}
          >
            <ChevronLeftIcon
              className="h-4 w-4 text-blue mr-3"
              aria-hidden="true"
              strokeWidth={3}
            />
            Back to Login
          </button>
        </div>
      </form>

      {/* Pass the dynamic title and content to CommonDialog */}
      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showRightIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default RegisterForm;
