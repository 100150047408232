import React from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "../shared/DataTable";
import StatsCard from "../components/dashboard/StatsCard";
import ReportType from "../components/dashboard/ReportType";
import ReportFileType from "../components/dashboard/ReportFileType";
import PatientProfile from "../components/dashboard/PatientProfile";
import NewReports from "../components/dashboard/NewReports";
import PrimaryButton from "../shared/PrimaryButton";
import { ReactComponent as ReportIcon } from "../assets/report-icon.svg";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

const DashboardPage = () => {
  const navigate = useNavigate();

  return (
    <div className="p-6 gap-4 min-h-screen">
      {/* Stats Cards */}
      <div className="grid md:grid-cols-3 xxs:grid-cols-1 md:gap-x-6 xxs:gap-x-0 gap-y-6">
        <div className="col-span-2">
          <StatsCard />
        </div>
        <div
          className="flex flex-col justify-between space-y-6 w-full bg-[var(--color-white)] p-4 rounded-[10px]"
          style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
        >
          <p className="text-[var(--color-dark-grey)] medium-text">
            Generate a detailed radiology report with just a few clicks.
          </p>
          <PrimaryButton
            type="submit"
            label="Generate Report"
            rightIcon={<ReportIcon className="w-6 h-6 -mt-1" />}
            className="w-full"
            onClick={() => {
              navigate("/create-report/report-type");
            }}
          />
        </div>
      </div>

      {/* Report Cards */}
      <div className="flex md:flex-row xxs:flex-col mt-6 mb-6 md:gap-x-6 xxs:gap-x-0 gap-y-6">
        <div className="md:w-3/5 xxs:w-full">
          <ReportType />
        </div>
        <div className="md:w-2/5 xxs:w-full">
          <ReportFileType />
        </div>
      </div>

      {/* Data Table */}
      <div className="flex md:flex-row xxs:flex-col mb-6 md:gap-x-6 xxs:gap-x-0 gap-y-6">
        <div className="md:w-4/6 xxs:w-full">
          <div className="bg-[var(--color-white)] flex items-center justify-between p-6">
            <span className="text-lg font-medium text-blue-text">
              Recently Added Reports
            </span>
            <div
              className="px-6 py-1.5 rounded-md flex space-x-2 items-center border border-dark-grey text-[var(--color-dark-grey)] text-sm cursor-pointer"
              onClick={() => {
                navigate("/report-management");
              }}
            >
              <div className="flex items-start">
                <p>View All</p>
              </div>
              <ChevronRightIcon className="w-4 h-4" />
            </div>
          </div>
          <DataTable rows={5} />
        </div>
        <div className="flex md:w-2/6 xxs:w-full flex-col space-y-6">
          <NewReports />
          <PatientProfile />
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
