import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";

// Function to check if the user is authenticated
const isAuthenticated = () => {
  const token = localStorage.getItem("authToken");
  return !!token; // Returns true if token exists, otherwise false
};

const ProtectedRoute = () => {
  const location = useLocation();
  const isAuthRequired = !["/verify-otp", "/reset-password"].includes(location.pathname);

  // If authentication is required and the user is not authenticated, redirect to login
  if (isAuthRequired && !isAuthenticated()) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Specific check for verify-otp and reset-password routes to prevent direct access
  if (["/verify-otp", "/reset-password"].includes(location.pathname)) {
    const fromForgotPassword = location.state?.fromForgotPassword; // Check for state

    // If the user is not navigating from the forgot password process, redirect to login
    if (!fromForgotPassword) {
      return <Navigate to="/login" replace />;
    }
  }

  return <Outlet />;
};

export default ProtectedRoute;
