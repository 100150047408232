import React from "react";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";

const PlanCard = ({ title, price, features, onChoose, planId }) => {
  // Conditionally apply group-hover styles only for the "advance" plan
  const isAdvancePlan = planId === "advance";

  return (
    <div
      className={`rounded-[10px] xxs:w-full md:w-1/3 border border-blue-text bg-text1 ${
        isAdvancePlan ? "dark:bg-white-dark" : "bg-white dark:bg-white-dark"
      }`}
    >
      <div className="padding-responsive pb-4 text-center">
        <div className="mb-6 text-center">
          <span
            className={`large-text outline outline-blue-text px-3 py-1 rounded-lg text-blue-text font-semibold ${
              isAdvancePlan ? "text-white outline-white" : ""
            }`}
          >
            {title}
          </span>
        </div>
        <p
          className={`text-blue-text mb-2 extra-large-text font-semibold ${
            isAdvancePlan ? "text-white" : ""
          }`}
        >
          ${price}
        </p>
        <p className="font-semibold text-text2 small-text">User/Month</p>

        <div className="mt-4 mb-6 space-y-1 small-text">
          {features.map((feature, index) => (
            <div key={index} className="flex items-start space-x-3">
              {/* Icon */}
              {feature.available ? (
                <div className="flex-shrink-0 inline-flex items-center justify-center h-3.5 w-3.5 m-1 rounded-full outline outline-main2">
                  <CheckIcon
                    className="h-3 w-3 text-main2"
                    aria-hidden="true"
                    strokeWidth={3}
                  />
                </div>
              ) : (
                <div className="flex-shrink-0 inline-flex items-center justify-center h-3.5 w-3.5 m-0.5 mt-1 ml-1 rounded-full outline outline-text2">
                  <XMarkIcon
                    className="h-3 w-3 text-text2"
                    aria-hidden="true"
                    strokeWidth={3}
                  />
                </div>
              )}
              {/* Feature text */}
              <span
                className={`${
                  feature.available
                    ? `text-primary dark:text-primary-light ${isAdvancePlan ? "text-white" : ""}`
                    : "text-text2 line-through"
                } text-start`}
              >
                {feature.name}
              </span>
            </div>
          ))}
        </div>

        <button
          onClick={onChoose}
          className={`py-3 text-[var(--color-primary)] font-medium text-xl rounded-lg w-full ${
            isAdvancePlan ? "bg-white text-black" : "bg-primary-light2 dark:bg-primary-light"
          }`}
        >
          Choose Plan
        </button>
      </div>
    </div>
  );
};

export default PlanCard;
