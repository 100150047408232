import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

export const getNotifications = async (limit = null) => {
  try {
    const url = limit
      ? `/notifications/get-notifications?limit=${limit}`
      : "/notifications/get-notifications";
    const response = await apiClient.get(url);
    return response.data;
  } catch (error) {
    toast.error(
      error.response?.data?.error || "Failed to fetch notifications!"
    ); // Error toast
    throw error.response.data;
  }
};

export const markNotificationAsRead = async (notificationIds) => {
  try {
    const response = await apiClient.post(
      `/notifications/mark-notifications-as-read`,
      {
        notification_ids: notificationIds,
      }
    );
    return response.data;
  } catch (error) {
    toast.error(
      error.response?.data?.error || "Failed to mark notification as read!"
    );
    throw error.response.data;
  }
};

export const deleteNotification = async (notificationIds) => {
  try {
    const response = await apiClient.post(
      `/notifications/delete-notifications`,
      {
        notification_ids: notificationIds,
      }
    );
    return response.data;
  } catch (error) {
    toast.error(
      error.response?.data?.error || "Failed to delete notification!"
    );
    throw error.response.data;
  }
};

export const filterNotificationsByDate = async (startDate, endDate) => {
  try {
    const response = await apiClient.get(
      `/notifications/filter-notifications-by-date`,
      {
        params: { start_date: startDate, end_date: endDate },
      }
    );
    return response.data;
  } catch (error) {
    toast.error(
      error.response?.data?.error || "Failed to fetch notifications!"
    );
    throw error.response.data;
  }
};
