import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

export const createReport = async (reportData) => {
    try {
        const response = await apiClient.post("/report/create-report", reportData);
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.error || "Failed to create report!"); // Error toast
        throw error.response.data;
    }
};

export const getReports = async () => {
    try {
        const response = await apiClient.get("/report/get-all-reports");
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.error || "Failed to fetch reports!"); // Error toast
        throw error.response.data;
    }
}

export const deleteReport = async (reportId) => {
    try {
        const response = await apiClient.delete(`/report/delete-report/${reportId}`);
        toast.success("Report deleted successfully!");
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.error || "Failed to delete report!"); // Error toast
        throw error.response.data;
    }
}