import React from "react";
import { ReactComponent as EditIcon } from "../../assets/edit.svg";
import Report from "../../shared/Report";
import { useNavigate } from "react-router-dom";

const SubmitStep = ({ reportDetails }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex items-center justify-between px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Report
        <button
          type="button"
          className="flex bg-blue p-2 px-3 text-sm font-medium text-white rounded-lg"
          onClick={() =>
            navigate("/edit-report/:reportId", {
              state: { fromSubmitStep: true },
            })
          }
        >
          <EditIcon className="mt-0.5 mr-2" />
          Edit
        </button>
      </div>

      <div className="m-6">
        <Report reportDetails={reportDetails} />
      </div>
    </div>
  );
};

export default SubmitStep;
