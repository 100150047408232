import React from "react";
import Divider from "@mui/material/Divider";

function Report({ reportDetails }) {
  // Calculate age from date of birth
  const calculateAge = (dob) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    // Adjust age if the birth date hasn't occurred yet this year
    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const age = reportDetails?.dob ? calculateAge(reportDetails.dob) : "34";

  return (
    <div className="bg-[var(--color-white)]">
      <div className="flex p-4 justify-between">
        <div className="flex flex-col">
          <div className="flex items-top">
            <div className="w-10 h-10 bg-grey4"></div>
            <span className="ml-2 mt-1 mediumLarge-text font-medium text-[var(--color-text1)]">
              RK Diagnostics
            </span>
          </div>
          <div className="mt-2">
            <div>
              <span className="text-xs text-[var(--color-dark-grey)]">Contact:</span>{" "}
              <span className="text-sm font-medium text-[var(--color-text1)]">
                +91 1234567890
              </span>
            </div>
            <div>
              <span className="text-xs text-[var(--color-dark-grey)]">Venue:</span>{" "}
              <span className="text-sm font-medium text-[var(--color-text1)]">
                Lorem Ipsum
              </span>
            </div>
            <div>
              <span className="text-xs text-[var(--color-dark-grey)]">Timing:</span>{" "}
              <span className="text-sm font-medium text-[var(--color-text1)]">
                10 AM - 8PM
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div>
            <span className="text-xs text-[var(--color-dark-grey)]">Patient Name:</span>{" "}
            <span className="text-sm font-medium text-[var(--color-text1)]">
              {reportDetails?.firstName || "ALEN"} {reportDetails?.lastName || "ALEX"}
            </span>
          </div>
          <div>
            <span className="text-xs text-[var(--color-dark-grey)]">Contact:</span>{" "}
            <span className="text-sm font-medium text-[var(--color-text1)]">
              {reportDetails?.contactNumber || "+56 896456723"}
            </span>
          </div>
          <div>
            <span className="text-xs text-[var(--color-dark-grey)]">Gender:</span>{" "}
            <span className="text-sm font-medium text-[var(--color-text1)]">
              {reportDetails?.gender?.label || "Male"}
            </span>
          </div>
          <div>
            <span className="text-xs text-[var(--color-dark-grey)]">Age:</span>{" "}
            <span className="text-sm font-medium text-[var(--color-text1)]">{age} years</span>
          </div>
          <div>
            <span className="text-xs text-[var(--color-dark-grey)]">Weight:</span>{" "}
            <span className="text-sm font-medium text-[var(--color-text1)]">
              {reportDetails?.weight || "70"} kg
            </span>
          </div>
          <div>
            <span className="text-xs text-[var(--color-dark-grey)]">Height:</span>{" "}
            <span className="text-sm font-medium text-[var(--color-text1)]">
              {reportDetails?.height || "175"} cm
            </span>
          </div>
        </div>
      </div>
      <div className="text-center space-y-2">
        <Divider orientation="horizontal" sx={{ borderColor: "#5F6177" }} />
        <div className="text-lg text-[var(--color-text1)]">
          {reportDetails?.reportType?.label || "MRI Report"}
        </div>
        <Divider orientation="horizontal" sx={{ borderColor: "#5F6177" }} />
      </div>

      {/* Diagnostic Impression Section */}
      <div className="mt-6 text-[var(--color-text1)] pb-20">
        <div className="ml-20">
          <p className="font-semibold text-lg underline">
            DIAGNOSTIC IMPRESSION:
          </p>
          <p className="ml-6">Examination without pathological findings.</p>

          {/* HIP MRI Section */}
          <p className="font-semibold text-lg mt-2 ml-10">HIP MRI</p>
          <p className="mt-2 ml-16">Medical history:</p>
          <div className="ml-20 mt-2">
            <p>Findings:</p>
            <ul className="list-disc ml-6">
              <li>
                The coxofemoral joint presents a normal amplitude, with no
                evidence of an arthropathy.
              </li>
              <li>There is no evidence of joint effusion.</li>
              <li>Acetabular coverage within normal limits.</li>
              <li>Alpha angle of...</li>
              <li>Articular cartilage with no evidence of alterations.</li>
              <li>Labrum of normal morphology, with no images of tears.</li>
              <li>
                Distension with fluid of the trochanteric bursae is not
                identified.
              </li>
              <li>The signal from the musculature is normal.</li>
              <li>Bone marrow with normal signal.</li>
              <li>Pubic symphysis without alterations.</li>
              <li>
                No signal alterations are observed in the evaluated area of the
                sacroiliac joints.
              </li>
              {/* Add more findings as necessary */}
            </ul>
          </div>
        </div>

        <Divider orientation="horizontal" sx={{ borderColor: "#B9B9B9" }} />

        <div className="mt-4 ml-20">
          <p className="font-semibold text-lg underline">
            DIAGNOSTIC IMPRESSION:
          </p>
          <p className="ml-6">Examination without pathological findings.</p>

          {/* Shoulder MRI Section */}
          <p className="font-semibold text-lg mt-2 ml-10">SHOULDER MRI</p>
          <div className="ml-16 mt-2">
            <p>Findings:</p>
            <ul className="list-disc ml-6">
              <li>
                The tendon of the supraspinatus, infraspinatus, pectoralis
                major, subscapularis, and teres minor muscles do not show
                alterations.
              </li>
              <li>Glenohumeral joint of normal morphology.</li>
              <li>Glenoid labrum with no evidence of alterations.</li>
              {/* Add more findings as necessary */}
            </ul>
          </div>
        </div>

        <Divider orientation="horizontal" sx={{ borderColor: "#B9B9B9" }} />

        {/* Conclusion Section */}
        <div className="mt-4 ml-20">
          <p className="font-semibold text-lg mt-4">CONCLUSION:</p>
          <ul className="list-disc ml-10 mt-6">
            <li>The signal from the bone marrow is normal.</li>
            <li>Sacroiliac joints and pubic symphysis of normal appearance.</li>
            <li>I do not observe a solid or cystic expansive process.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Report;
