import React from "react";
import { ReactComponent as MRI } from "../../assets/mri.svg";
import { ReactComponent as ECG } from "../../assets/ecg.svg";
import { ReactComponent as XRAY } from "../../assets/x-ray.svg";
import MailIcon from "@mui/icons-material/Mail";

const NewReports = () => {
  return (
    <div
      className="bg-[var(--color-white)] p-4 rounded-[10px] w-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      {/* Header and Dropdown */}
      <div className="mb-4 p-2">
        <span className="text-lg font-medium text-blue-text">
          New Reports Generated
        </span>
      </div>

      {/* Icons for file upload and mic */}
      <div className="flex flex-col space-y-4">
        <div className="flex justify-between items-center space-x-2">
          <div className="flex space-x-2">
            <div
              className="p-2 rounded-full"
              style={{ backgroundColor: "#7367F033" }}
            >
              <MRI />
            </div>
            <div>
              <span className="text-lg text-[var(--color-text1)]">
                Roselle Ehrman
              </span>
              <p className="text-sm text-blue-text">MRI</p>
            </div>
          </div>
          <div className="space-x-2 p-2 rounded-full bg-[#F5F5F5] dark:bg-white-light">
            <MailIcon sx={{ color: "#FCAA5C" }} />
          </div>
        </div>
        <div className="flex justify-between items-center space-x-2">
          <div className="flex space-x-2">
            <div
              className="p-2.5 rounded-full"
              style={{ backgroundColor: "#FF4C5133" }}
            >
              <ECG />
            </div>
            <div>
              <span className="text-lg text-[var(--color-text1)]">
                John Smith
              </span>
              <p className="text-sm text-blue-text">ECG</p>
            </div>
          </div>
          <div className="space-x-2 p-2 rounded-full bg-[#F5F5F5] dark:bg-white-light">
            <MailIcon sx={{ color: "#FCAA5C" }} />
          </div>
        </div>
        <div className="flex justify-between items-center space-x-2">
          <div className="flex space-x-2">
            <div
              className="p-2.5 rounded-full"
              style={{ backgroundColor: "#00BAD133" }}
            >
              <XRAY />
            </div>
            <div>
              <span className="text-lg text-[var(--color-text1)]">
                Leatrice Kulik
              </span>
              <p className="text-sm text-blue-text">X-RAY</p>
            </div>
          </div>
          <div className="space-x-2 p-2 rounded-full bg-[#F5F5F5] dark:bg-white-light">
            <MailIcon sx={{ color: "#FCAA5C" }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewReports;
