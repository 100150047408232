import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Dropdown from "../../shared/DropDown";

function Notifications() {
  const [selectedNotification, setSelectedNotification] = useState({
    value: "online",
    label: "Only When I’m online",
  });
  const notificationOptions = [
    { value: "online", label: "Only When I’m online" },
    { value: "anytime", label: "Anytime" },
  ];

  const handleNotificationChange = (option) => {
    setSelectedNotification(option);
  };

  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: theme.palette.mode === "light" ? "#FFFFFF" : "#E6E6E6", // Thumb color
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "light" ? "#1BB13C" : "#1BB13C", // Green for dark mode
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 20,
      height: 20,
      boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.1)", // Subtle shadow
    },
    "& .MuiSwitch-track": {
      borderRadius: 15,
      backgroundColor:
        theme.palette.mode === "light" ? "#E6E6E6" : "#333649", // Neutral background for dark mode
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));  

  return (
    <div className="h-[80vh]">
      <div className="flex flex-col m-6 bg-[var(--color-white)] rounded-[10px]">
        <div className="flex items-center justify-between">
          <div className="px-4 py-2">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">
              New Report Assigned
            </p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Stay informed on upcoming deadlines or overdue items
            </span>
          </div>
          <IOSSwitch sx={{ mr: 2 }} defaultChecked />
        </div>
        <Divider
          orientation="horizontal"
          sx={{
            border: "1px solid #B9B9B9",
          }}
          flexItem
        />
        <div className="flex items-center justify-between">
          <div className="px-4 py-2">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">Comment</p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Keep track of time-sensitive tasks or pending actions
            </span>
          </div>
          <IOSSwitch sx={{ mr: 2 }} defaultChecked />
        </div>
        <Divider
          sx={{
            border: "1px solid #B9B9B9",
          }}
          orientation="horizontal"
          flexItem
        />
        <div className="flex items-center justify-between">
          <div className="px-4 py-2">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">Mentions</p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Don’t miss important updates or overdue responsibilities
            </span>
          </div>
          <IOSSwitch sx={{ mr: 2 }} defaultChecked />
        </div>
        <Divider
          sx={{
            border: "1px solid #B9B9B9",
          }}
          orientation="horizontal"
          flexItem
        />
        <div className="flex items-center justify-between">
          <div className="px-4 py-2">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">Report Created</p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Monitor upcoming due dates or past-due tasks
            </span>
          </div>
          <IOSSwitch sx={{ mr: 2 }} defaultChecked />
        </div>
        <Divider
          sx={{
            border: "1px solid #B9B9B9",
          }}
          orientation="horizontal"
          flexItem
        />
        <div className="flex items-center justify-between">
          <div className="px-4 py-2">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">Report Deleted</p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Ensure no pending items are missed
            </span>
          </div>
          <IOSSwitch sx={{ mr: 2 }} defaultChecked />
        </div>
        <Divider
          sx={{
            border: "1px solid #B9B9B9",
          }}
          orientation="horizontal"
          flexItem
        />
        <div className="flex items-center justify-between">
          <div className="px-4 py-2">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">Update Version</p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Stay informed with updates when the system version changes.
            </span>
          </div>
          <IOSSwitch sx={{ mr: 2 }} defaultChecked />
        </div>
        <Divider orientation="horizontal" flexItem />

        <div className="mt-6 mb-6 px-4 w-1/3">
          <p className="xxs:text-sm md:text-lg text-[var(--color-text1)] mb-1">
            When should we send you notification?
          </p>
          <Dropdown
            id="notification"
            options={notificationOptions}
            selectedOption={selectedNotification}
            onChange={handleNotificationChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Notifications;
