import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

// Get User API
export const getUser = async () => {
  try {
    const response = await apiClient.get("/user/profile");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to fetch user!"); // Error toast
    throw error.response.data;
  }
};

// Update User API
export const updateUserProfile = async (userData) => {
  try {
    const response = await apiClient.put("/user/profile/edit", userData);
    toast.success("Profile updated successfully!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to update user!"); // Error toast
    throw error.response.data;
  }
};

// Change Password API
export const changePassword = async (passwordData) => {
  try {
    const response = await apiClient.post(
      "/user/change-password",
      passwordData
    );
    toast.success("Password changed successfully!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to change password!"); // Error toast
    throw error.response.data;
  }
};

// Create User API
export const createUser = async (userData) => {
  try {
    const response = await apiClient.post("/user/create", userData);
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "User creation failed!"); // Error toast
    throw error.response.data;
  }
};

// Get Users API
export const getUsers = async () => {
  try {
    const response = await apiClient.get("/user/getusers");
    return response.data.users;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to fetch users!");
    throw error.response.data;
  }
};

// Remove User API
export const deleteUser = async (email) => {
  try {
    const response = await apiClient.delete("/user/delete", {
      data: { email }, // Sending email in the request body
    });
    toast.success(`User with email ${email} has been successfully removed.`);
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to remove user!");
    throw error.response.data;
  }
};

// Update User API
export const updateUser = async (userData) => {
  try {
    const response = await apiClient.put("/user/update", userData);
    toast.success(
      "User with email " + userData.email + " has been successfully updated."
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to update user!");
    throw error.response.data;
  }
};
