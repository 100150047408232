import React from "react";
import { useTheme } from "@mui/material/styles";
import { Checkbox, FormControlLabel } from "@mui/material";

const CustomCheckbox = ({ id, label, checked, size, onChange }) => {
  const theme = useTheme();

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          checked={checked}
          onChange={onChange}
          size={size}
          sx={{
            color: "#5f6177",
            "&.Mui-checked": {
              color: theme.palette.mode === "dark" ? "#F3F3F3" : "#333333",
            },
            "&:hover": {
              backgroundColor: "transparent", // Remove background on hover
            },
            // Remove default focus ring
            "&:focus-visible": {
              outline: "none",
              boxShadow: "none",
            },
            // Remove default margin and padding
            margin: 0,
            padding: 0,
            marginLeft: "0.7rem",
            marginRight: "0.5rem",
          }}
        />
      }
      label={
        <span className="text-[var(--color-dark-grey)] small-text">
          {label}
        </span>
      }
    />
  );
};

export default CustomCheckbox;
