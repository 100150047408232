import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment, Pagination } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import HistoryIcon from "@mui/icons-material/History";
import Checkbox from "../shared/Checkbox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { formatDistanceToNow } from "date-fns";
import { getNotifications } from "../services/notificationsService";
// import { markNotificationAsRead } from "../services/notificationsService";
// import { filterNotificationsByDate } from "../services/notificationsService";
// import { deleteNotification } from "../services/notificationsService";

const NotificationsPage = () => {
  const theme = useTheme();
  const notificationRef = useRef(null);
  const dateRangeRef = useRef(null);
  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);
  const notificationOptions = ["Select", "Select All", "Delete"];
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const paginatedNotifications = notifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleActionSelect = (option) => {
    setShowNotificationDropdown(false); // Close the dropdown after selection
  };

  // State for the selected tab
  const [selectedTab, setSelectedTab] = useState("All");

  // State for date range picker
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#513ADC",
    },
  ]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowNotificationDropdown(false);
        setShowDatePickerDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNotifications = useCallback(async () => {
    try {
      const response = await getNotifications();
      setNotifications(response.notifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, []);

  // Call handleNotifications as soon as the Navbar component loads
  useEffect(() => {
    handleNotifications();
  }, [handleNotifications]);

  // const handleMarkAsRead = async (notificationIds) => {
  //   try {
  //     await markNotificationAsRead(notificationIds); // Assuming this API marks notifications as read
  //     handleNotifications(); // Re-fetch notifications to reflect changes
  //   } catch (error) {
  //     console.error("Error marking notifications as read:", error);
  //   }
  // };

  // const handleDeleteNotifications = async (notificationIds) => {
  //   try {
  //     await deleteNotification(notificationIds); // Assuming this API deletes notifications
  //     handleNotifications(); // Re-fetch notifications to reflect changes
  //   } catch (error) {
  //     console.error("Error deleting notifications:", error);
  //   }
  // };

  // const handleDateFilter = useCallback(async () => {
  //   try {
  //     const response = await filterNotificationsByDate(
  //       dateRange[0].startDate,
  //       dateRange[0].endDate
  //     ); // Assuming the service accepts a date range
  //     setNotifications(response.notifications);
  //   } catch (error) {
  //     console.error("Error fetching notifications by date:", error);
  //   }
  // }, [dateRange]);

  return (
    <div>
      <div>
        {/* Heading */}
        <div className="flex p-6 xxs:flex-col sm:flex-row xxs:space-y-4 justify-between sm:items-center">
          <div className="flex items-center w-1/3">
            <TextField
              variant="outlined"
              placeholder="Search name, mobile..."
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #C9CBCD",
                    borderRadius: "8px !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #5F6177",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{
                        color:
                          theme.palette.mode === "light" ? "#11171999" : "grey",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "transparent" : "white",
                  borderRadius: "8px",
                  height: "50px",
                  fontSize: "14px",
                },
              }}
              className="w-full"
            />
            <button className="ml-4 p-3 rounded-lg bg-primary">
              <SearchIcon sx={{ color: "white" }} />
            </button>
          </div>
          <button
            className="p-2 px-10 w-max rounded-md bg-[#FFFFFF4D] dark:bg-white-light text-primary"
            style={{
              boxShadow: " 0px 4px 8px 0px #00000012",
            }}
          >
            Mark all as read
          </button>
        </div>

        <div
          className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 md:items-center justify-between bg-[var(--color-white)] p-4 mb-4"
          style={{ boxShadow: "0px 2px 6px 0px #00000024" }}
        >
          {/* Notification Tabs */}
          <div className="flex flex-wrap xxs:items-start gap-4 md:w-1/2 ml-2">
            {["All", "Unread", "Read", "New Notifications", "Mentions"].map(
              (tab) => (
                <button
                  key={tab}
                  onClick={() => handleTabClick(tab)}
                  className={`p-2 px-4 font-medium rounded-md ${
                    selectedTab === tab
                      ? "bg-primary text-white"
                      : "text-blue-text"
                  }`}
                >
                  {tab}
                </button>
              )
            )}
          </div>

          {/* Date Range Picker */}
          <div className="mr-2 relative cursor-pointer" ref={dateRangeRef}>
            <div
              className="p-2 text-sm border border-grey rounded-md w-max"
              onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
            >
              <span className="text-[#283037B2] dark:text-grey">
                Date Range:
              </span>
              <span className="text-[var(--color-text1)]">
                {" "}
                {dateRange[0].startDate.toDateString()} -{" "}
                {dateRange[0].endDate.toDateString()}
              </span>
              <CalendarMonthOutlinedIcon
                sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
              />
            </div>

            {showDatePickerDropdown && (
              <div
                className="absolute z-10 top-full md:right-0 mt-1 bg-[var(--color-white)] rounded-lg"
                style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
              >
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(item) => setDateRange([item.selection])}
                  locale={enGB}
                />
              </div>
            )}
          </div>
        </div>

        {/* Notifications List */}
        <div className="rounded-lg bg-[var(--color-white)] xxs:m-4 md:m-6">
          {notifications.length === 0 ? (
            <div className="p-4 flex justify-center items-center text-base">
              No notifications available
            </div>
          ) : (
            <div>
              <div className="flex items-center justify-end p-4">
                <div className="relative" ref={notificationRef}>
                  <button
                    className="ml-4 text-gray-500"
                    onClick={() =>
                      setShowNotificationDropdown(!showNotificationDropdown)
                    }
                  >
                    <MoreVertIcon sx={{ fontSize: "18px" }} />
                  </button>

                  {/* <p
                          key={option}
                          className={`cursor-pointer rounded-lg text-start small-text mt-1 mb-1 hover:bg-pink-light p-2 ${
                            selectedNotifOptions === option
                              ? "font-bold ring-1 ring-primary bg-primary-light hover:bg-primary-light hover:outline-none"
                              : ""
                          }`}
                          onClick={() => handleNotificationSelect(option)}
                        >
                          <span className="flex justify-between">
                            {option}
                            {selectedNotifOptions === option && (
                              <CheckIcon className="h-4 w-4" />
                            )}
                          </span>
                        </p> */}
                  {showNotificationDropdown && (
                    <div
                      className="absolute top-full right-0 z-10 mt-1 bg-[var(--color-white)] w-24 rounded-[10px]"
                      style={{
                        border: "1px solid #E7E7E7",
                        boxShadow: "0px 4px 16px 0px #00000029",
                      }}
                    >
                      {notificationOptions.map((option) => (
                        <p
                          key={option}
                          className={`cursor-pointer border-b small-text m-0 p-2 hover:bg-grey2 dark:hover:bg-white-light ${
                            option === "Select"
                              ? "rounded-t-[10px]" // Top rounded corners for the first item
                              : "" // No extra rounding for middle items
                          } ${option === "Select All" ? "rounded-none" : ""} ${
                            option === "Delete"
                              ? "rounded-b-[10px]" // Bottom rounded corners for the last item
                              : ""
                          }`}
                          onClick={() => {
                            handleActionSelect(option);
                          }}
                        >
                          {option}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {paginatedNotifications.map((notification) => (
                <div
                  key={notification.id}
                  className="flex items-start p-4 border-b border-light-grey hover:bg-background dark:hover:bg-white-light"
                >
                  <div className="flex items-center">
                    <Checkbox size="small" />
                    <CircleIcon
                      sx={{
                        color: "#007AFF",
                        fontSize: {
                          xxs: "8px",
                          md: "12px",
                        },
                        ml: -1.5,
                        mr: 1.5,
                      }}
                    />
                  </div>
                  <div className="-mt-0.5 flex-1">
                    <h3 className="text-[var(--color-text1)] medium-text">
                      {notification.title}
                    </h3>
                    <p className="text-sm mt-1 text-[var(--color-dark-grey)]">
                      {notification.message}
                    </p>
                    <p className="flex items-center mt-1 text-xs text-[var(--color-dark-grey)]">
                      <HistoryIcon sx={{ mr: 1, fontSize: "14px" }} />
                      {formatDistanceToNow(new Date(notification.created_at), { addSuffix: true }).replace(/^about /, '')}
                    </p>
                  </div>
                </div>
              ))}

              <div className="mt-2 flex items-center justify-end">
                <Pagination
                  count={Math.ceil(notifications.length / itemsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  sx={{ marginTop: "16px", marginBottom: "16px" }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;
