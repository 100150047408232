import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import GE from "../assets/GE.png";
import SearchIcon from "@mui/icons-material/Search";
import {
  TextField,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddIcCallOutlinedIcon from "@mui/icons-material/AddIcCallOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import VerifiedUserOutlinedIcon from "@mui/icons-material/VerifiedUserOutlined";
import { ReactComponent as LinkIcon } from "../assets/link.svg";
import { ReactComponent as RaysIcon } from "../assets/x-rays.svg";
import { ReactComponent as TopicIcon } from "../assets/topic.svg";

const categories = {
  "General FAQs": [
    {
      question: "What does a radiologist do?",
      answer:
        "A radiologist is a medical doctor who specializes in interpreting medical images such as X-rays, CT scans, MRIs, and ultrasounds. We help diagnose and sometimes treat diseases or conditions by interpreting these images.",
    },
    {
      question: "What types of medical imaging tests do you perform?",
      answer:
        "A radiologist is a medical doctor who specializes in interpreting medical images such as X-rays, CT scans, MRIs, and ultrasounds. We help diagnose and sometimes treat diseases or conditions by interpreting these images.",
    },
    {
      question: "What is the difference between X-Ray, CT-Scan & MRI?",
      answer:
        "A radiologist is a medical doctor who specializes in interpreting medical images such as X-rays, CT scans, MRIs, and ultrasounds. We help diagnose and sometimes treat diseases or conditions by interpreting these images.",
    },
    {
      question: "How can I raise ticket?",
      answer:
        "A radiologist is a medical doctor who specializes in interpreting medical images such as X-rays, CT scans, MRIs, and ultrasounds. We help diagnose and sometimes treat diseases or conditions by interpreting these images.",
    },
  ],
  "Procedures & Safety": [
    {
      question: "Are radiology procedures safe?",
      answer:
        "Yes, most imaging procedures are safe. We use the lowest radiation doses possible and take precautions to minimize exposure. MRI and ultrasound use no radiation at all.",
    },
    {
      question: "Will I feel anything during the scan?",
      answer:
        "Most scans are painless. However, if contrast material is used (like in some CT or MRI scans), you may feel a brief cold sensation or slight discomfort during injection.",
    },
    {
      question: "What is a contrast agent, and is it safe?",
      answer:
        "A contrast agent helps to improve the visibility of certain structures in your body during imaging. It's usually safe, but like any medical substance, it can cause rare allergic reactions or side effects. We monitor patients closely.",
    },
  ],
  "Results & Interpretation": [
    {
      question: "What does a radiologist do?",
      answer:
        "A radiologist is a medical doctor who specializes in interpreting medical images...",
    },
    {
      question: "What types of medical imaging tests do you perform?",
      answer: "We perform X-rays, CT scans, MRI, and ultrasounds...",
    },
    {
      question: "What is the difference between X-Ray, CT-Scan & MRI?",
      answer:
        "X-rays use radiation, CT scans are detailed x-rays, and MRIs use magnetic fields...",
    },
    {
      question: "How can I raise ticket?",
      answer:
        "You can raise a ticket by contacting our support team via phone or email.",
    },
  ],
  "Radiation Exposure": [
    {
      question: "What does a radiologist do?",
      answer:
        "A radiologist is a medical doctor who specializes in interpreting medical images...",
    },
    {
      question: "What types of medical imaging tests do you perform?",
      answer: "We perform X-rays, CT scans, MRI, and ultrasounds...",
    },
    {
      question: "What is the difference between X-Ray, CT-Scan & MRI?",
      answer:
        "X-rays use radiation, CT scans are detailed x-rays, and MRIs use magnetic fields...",
    },
    {
      question: "How can I raise ticket?",
      answer:
        "You can raise a ticket by contacting our support team via phone or email.",
    },
  ],
  "Specialized Topics": [
    {
      question: "What does a radiologist do?",
      answer:
        "A radiologist is a medical doctor who specializes in interpreting medical images...",
    },
    {
      question: "What types of medical imaging tests do you perform?",
      answer: "We perform X-rays, CT scans, MRI, and ultrasounds...",
    },
    {
      question: "What is the difference between X-Ray, CT-Scan & MRI?",
      answer:
        "X-rays use radiation, CT scans are detailed x-rays, and MRIs use magnetic fields...",
    },
    {
      question: "How can I raise ticket?",
      answer:
        "You can raise a ticket by contacting our support team via phone or email.",
    },
  ],
};

function SupportPage() {
  const theme = useTheme();
  const [selectedCategory, setSelectedCategory] = useState("General FAQs");

  return (
    <div className="flex flex-col">
      {/* Header Section */}
      <div
        className="m-6 bg-cover bg-center flex flex-col items-center justify-center rounded-lg bg-[var(--color-white)]"
        style={{ backgroundImage: `url(${GE})` }}
      >
        <div className="xxs:p-6 md:py-16 md:px-12 space-y-6 w-full flex flex-col items-center">
          <div className="text-center">
            <p className="large-text text-[var(--color-text1)]">
              Hello, how can we help you?
            </p>
            <span className="text-[var(--color-dark-grey)] xxs:text-sm md:text-lg">
              or choose category to quickly find the help you need
            </span>
          </div>
          <div className="flex xxs:w-full md:w-7/12">
            <TextField
              variant="outlined"
              placeholder="Search name, mobile..."
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #C9CBCD",
                    borderRadius: "8px !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #5F6177",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{
                        color:
                          theme.palette.mode === "light" ? "#11171999" : "grey",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "transparent" : "white",
                  borderRadius: "8px",
                  height: "45px",
                  fontSize: "14px",
                },
              }}
              className="w-full"
            />
            <button className="ml-4 p-2.5 rounded-lg bg-primary">
              <SearchIcon sx={{ color: "white" }} />
            </button>
          </div>
        </div>
      </div>

      {/* Main Content Section */}
      <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 m-6">
        {/* Sidebar Navigation */}
        <div className="md:w-1/4">
          {Object.keys(categories).map((category) => (
            <div
              key={category}
              className={`flex items-center medium-text p-3 mr-4 cursor-pointer rounded-md ${
                selectedCategory === category
                  ? "bg-primary text-white"
                  : "text-[var(--color-text1)]"
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {/* Icons based on category */}
              {category === "General FAQs" && (
                <HelpOutlineRoundedIcon sx={{ mr: 1 }} />
              )}
              {category === "Procedures & Safety" && (
                <VerifiedUserOutlinedIcon sx={{ mr: 1 }} />
              )}
              {category === "Results & Interpretation" && (
                <LinkIcon className="mr-2" />
              )}
              {category === "Radiation Exposure" && (
                <RaysIcon className="mr-2" />
              )}
              {category === "Specialized Topics" && (
                <TopicIcon className="mr-2" />
              )}
              {category}
            </div>
          ))}
        </div>

        {/* Accordions for Selected Category */}
        <div className="md:w-3/4 pl-2">
          <div className="flex items-center">
            <div className="p-2 mr-2 rounded-full w-fit bg-[#1725A61A] dark:bg-primary-light">
              {/* Icons based on category */}
              {selectedCategory === "General FAQs" && (
                <HelpOutlineRoundedIcon color="primary" />
              )}
              {selectedCategory === "Procedures & Safety" && (
                <VerifiedUserOutlinedIcon color="primary" />
              )}
              {selectedCategory === "Results & Interpretation" && (
                <LinkIcon className="text-primary" />
              )}
              {selectedCategory === "Radiation Exposure" && (
                <RaysIcon className="text-primary" />
              )}
              {selectedCategory === "Specialized Topics" && (
                <TopicIcon className="text-primary" />
              )}
            </div>
            <span className="text-lg text-[var(--color-text1)]">
              {selectedCategory}
            </span>
          </div>
          <p className="text-blue-text mb-2 ml-2.5 m-1">
            Get help with Setting
          </p>
          {categories[selectedCategory].map((faq, index) => (
            <Accordion
              key={index}
              sx={{
                borderRadius: "10px !important",
                boxShadow: "0px 4px 16px 0px #00000029",
                marginBottom: "10px",
                border: "1px solid #E4E4E4",
              }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <span className="text-[var(--color-text1)]">
                  {faq.question}
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <span className="text-[var(--color-dark-grey)]">
                  {faq.answer}
                </span>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </div>

      {/* Footer Section */}
      <div className="text-center flex flex-col items-center m-4">
        <div
          className="text-highlight rounded-lg p-2 w-fit"
          style={{ backgroundColor: "#F4E5FF" }}
        >
          Question?
        </div>
        <p className="text-highlight text-lg font-semibold mt-2">
          You still have a question?
        </p>
        <p className="text-[var(--color-dark-grey)]">
          If you can't find a question in our FAQ, you can contact us. We'll
          answer you shortly!
        </p>
      </div>

      <div className="m-6 px-6 py-8 bg-[var(--color-white)] flex flex-col items-center justify-center rounded-lg outline-dashed outline-grey">
        <div className="xxs:w-full md:w-1/3 text-center">
          <p className="xxs:text-base md:text-xl text-[var(--color-text1)] font-medium">
            <PhoneIcon
              sx={{ color: "#2A64F6", fontSize: { xxs: 24, md: 30 }, mr: 1 }}
            />{" "}
            Contact Us
          </p>
          <p className="text-[var(--color-dark-grey)] xxs:text-sm md:text-lg mt-4">
            3rd Floor, Unit 305A, Marathon Icon, Off GK Marg, Lower Pearl (W),
            USA
          </p>
          <p className="xxs:text-sm md:text-lg text-[var(--color-text1)] mt-4">
            <MailOutlineIcon sx={{ fontSize: "22px", mr: 1 }} />{" "}
            info@emilio.muse.in
          </p>
          <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">
            <AddIcCallOutlinedIcon sx={{ fontSize: "24px", mr: 1 }} /> +56
            708-790-0000
          </p>
        </div>
      </div>
    </div>
  );
}

export default SupportPage;
