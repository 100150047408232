import React, { useState, useEffect } from "react";
import Dropdown from "../../shared/DropDown";
import TextInput from "../../shared/TextInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ReportImg from "../../assets/report.png";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { getReportTemplates } from "../../services/reportTemplateService";
import { getReportTemplateById } from "../../services/reportTemplateService";
import dayjs from "dayjs";

const ReportTypeStep = ({ reportDetails, setReportDetails, setFormValid }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null); // State for selected template
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const [reportTemplates, setReportTemplates] = useState([]);

  const reportOptions = [
    { value: "x-ray", label: "X-Ray" },
    { value: "mri", label: "MRI" },
    { value: "ecg", label: "ECG" },
    { value: "ct-scan", label: "CT-Scan" },
    { value: "sonography", label: "Sonography" },
  ];

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  // Validate input fields
  const validateField = (field, value) => {
    let error = "";

    switch (field) {
      case "firstName":
        if (!value || !/^[A-Za-z]+$/.test(value)) {
          error = "First name must contain only alphabets.";
        }
        break;

      case "lastName":
        if (!value || !/^[A-Za-z]+$/.test(value)) {
          error = "Last name must contain only alphabets.";
        }
        break;

      case "email":
        if (!value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
          error = "Invalid email format.";
        }
        break;

      case "contactNumber":
        if (!value || !/^[0-9]{10}$/.test(value)) {
          error = "Contact number must be 10 digits.";
        }
        break;

      case "height":
        if (!value || !/^[0-9]+$/.test(value)) {
          error = "Height must be a number.";
        }
        break;

      case "weight":
        if (!value || !/^[0-9]+$/.test(value)) {
          error = "Weight must be a number.";
        }
        break;

      case "reportType":
        if (!value) {
          error = "Report type is required.";
        }
        break;

      case "gender":
        if (!value) {
          error = "Gender is required.";
        }
        break;

      case "dob":
        if (!value) {
          error = "Date of birth is required.";
        }
        break;

      case "template":
        if (!value) {
          error = "Template is required.";
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleReportChange = (report) => {
    setReportDetails({ ...reportDetails, reportType: report });
  };

  const handleInputChange = (field, value) => {
    setReportDetails((prevDetails) => {
      const updatedDetails = {
        ...prevDetails,
        [field]: value,
      };

      // Validate the updated field
      const error = validateField(field, value);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: error,
      }));

      // Check if all required fields are valid
      const requiredFields = [
        "firstName",
        "lastName",
        "email",
        "contactNumber",
        "height",
        "weight",
        "reportType",
        "gender",
        "dob",
        "template",
      ];
      const allFieldsFilled = requiredFields.every(
        (key) =>
          updatedDetails[key] && updatedDetails[key].toString().trim() !== ""
      );

      // Check overall form validity
      const isValid =
        allFieldsFilled &&
        Object.values({
          ...errors,
          [field]: error,
        }).every((e) => e === "");

      setFormValid(isValid); // Update form validity
      return updatedDetails;
    });
  };

  useEffect(() => {
    const fetchReportTemplates = async () => {
      try {
        const response = await getReportTemplates(); // Call the getUser API
        setReportTemplates(response.reports); // Assume response.data contains the array of users
      } catch (error) {}
    };
    fetchReportTemplates();
  }, []);

  const fetchTemplateById = async (templateId) => {
    try {
      const response = await getReportTemplateById(templateId); // API call to fetch template details
      setReportDetails((prevDetails) => ({
        ...prevDetails,
        templateContent: response.template_content, // Save the template content to reportDetails
      }));
    } catch (error) {
      console.error("Error fetching template details:", error);
    }
  };

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template.id);
    setReportDetails((prevDetails) => ({
      ...prevDetails,
      template: template.id,
    }));
    fetchTemplateById(template.id);
  };

  return (
    <div>
      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Select Report
      </div>
      <div className="m-6 p-3 bg-[var(--color-white)] rounded-lg">
        <Dropdown
          id="report"
          label="Report Type"
          options={reportOptions}
          showLabel={true}
          required={true}
          selectedOption={reportDetails.reportType}
          onChange={handleReportChange}
        />
      </div>

      {/* Choose Template Section */}
      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Choose Template
      </div>
      <div className="m-6 p-6 bg-[var(--color-white)] rounded-lg overflow-x-auto flex items-center gap-4">
        {reportTemplates.length > 0 ? (
          reportTemplates.map((template) => (
            <div
              key={template.id}
              onClick={() => handleTemplateSelect(template)}
              className={`relative cursor-pointer ${
                selectedTemplate === template.id
                  ? "py-2 px-1 border-2 border-primary"
                  : "border-light-grey"
              }`}
              style={
                selectedTemplate === template.id
                  ? { background: "#F4F7FE" }
                  : {}
              }
            >
              {selectedTemplate === template.id && (
                <CheckCircleIcon className="w-6 h-6 absolute -top-2.5 -right-3 text-primary bg-white rounded-full" />
              )}
              <div className="w-max border border-light-grey">
                <img src={ReportImg} alt="report" />
              </div>
              <p
                className={`text-center mt-1 ${
                  selectedTemplate === template.id
                    ? "text-dark-grey"
                    : "text-[var(--color-dark-grey)]"
                }`}
              >
                {template.report_name} {/* Display the dynamic report name */}
              </p>
            </div>
          ))
        ) : (
          <p>No templates available</p> // Handle the case when no templates are fetched
        )}
      </div>

      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Patient Details
      </div>
      <div className="m-6 p-6 bg-[var(--color-white)] rounded-lg">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-4 gap-x-12">
          <TextInput
            label="First Name"
            value={reportDetails.firstName || ""}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
            error={!!errors.firstName}
            helperText={errors.firstName}
            required={true}
          />

          <TextInput
            label="Last Name"
            value={reportDetails.lastName || ""}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
            error={!!errors.lastName}
            helperText={errors.lastName}
            required={true}
          />

          <div>
            <Dropdown
              id="gender"
              label="Gender"
              showLabel={true}
              required={true}
              options={genderOptions}
              selectedOption={reportDetails.gender}
              onChange={(option) => handleInputChange("gender", option)}
            />
            {errors.gender && (
              <p className="text-error text-xs mt-1 ml-2">{errors.gender}</p>
            )}
          </div>

          <div className="w-full">
            <div className="text-sm text-[var(--color-dark-grey)] mb-1">
              DOB (DD/MM/YY) <span style={{ color: "#FF1F00" }}>*</span>
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={reportDetails.dob || null}
                onChange={(newValue) => {
                  handleInputChange("dob", newValue ? dayjs(newValue) : null);
                  setOpen(false);
                }}
                slotProps={{
                  textField: {
                    size: "small",
                    placeholder: "Choose Date",
                    error: !!errors.dob,
                    helperText: errors.dob,
                    sx: {
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                      },
                    },
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setOpen(true)}>
                            <CalendarMonthOutlinedIcon sx={{ ml: 0 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <TextInput
            label="Email"
            value={reportDetails.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
            error={!!errors.email}
            helperText={errors.email}
            required={true}
          />

          <TextInput
            label="Contact Number"
            value={reportDetails.contactNumber || ""}
            onChange={(e) => handleInputChange("contactNumber", e.target.value)}
            error={!!errors.contactNumber}
            helperText={errors.contactNumber}
            required={true}
          />

          <TextInput
            label="Weight (kg)"
            value={reportDetails.weight || ""}
            onChange={(e) => handleInputChange("weight", e.target.value)}
            error={!!errors.weight}
            helperText={errors.weight}
            required={true}
          />

          <TextInput
            label="Height (cm)"
            value={reportDetails.height || ""}
            onChange={(e) => handleInputChange("height", e.target.value)}
            error={!!errors.height}
            helperText={errors.height}
            required={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportTypeStep;
