import React, { useState } from "react";
import Dropdown from "../../shared/DropDown";
import ProgressBar from "../../shared/ProgressBar";

const ReportType = () => {
  const [selectedTime, setSelectedTime] = useState(null);

  const timeOptions = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "yearly", label: "Yearly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "all", label: "All" },
  ];

  const handleOptionChange = (time) => {
    setSelectedTime(time);
  };

  return (
    <div
      className="bg-[var(--color-white)] p-4 rounded-[10px] w-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      {/* Header and Dropdown */}
      <div className="flex flex-wrap space-y-4 justify-between items-center mb-4">
        <span className="flex text-lg font-medium text-blue-text">
          Report Type
          <div className="bg-primary-light2 dark:bg-white-light -mt-1 ml-2 px-3 py-0.5 rounded-md">
            <span className="text-purple text-sm font-medium">200 Reports</span>
          </div>
        </span>
        <div className="w-40">
          <Dropdown
            id="time"
            label="option"
            options={timeOptions}
            selectedOption={selectedTime}
            onChange={handleOptionChange}
          />
        </div>
      </div>

      {/* Customizable Progress Bars */}
      <ProgressBar label="MRI" percentage={18} count={41} barColor="#F13C3C" />
      <ProgressBar label="ECG" percentage={29} count={56} barColor="#07BCD2" />
      <ProgressBar
        label="CT Scan"
        percentage={18}
        count={41}
        barColor="#FF9F43"
      />
      <ProgressBar
        label="X-Ray"
        percentage={35}
        count={84}
        barColor="#513ADC"
      />
    </div>
  );
};

export default ReportType;
