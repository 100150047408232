import React, { useState } from "react";
import TextInput from "../../shared/TextInput";
import PrimaryButton from "../../shared/PrimaryButton";
import { ReactComponent as ResetIcon } from "../../assets/reset-password_svgrepo.com.svg";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import CommonDialog from "../../shared/CommonDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { resetPassword } from "../../services/authService";
import { toast } from "react-toastify";

const ResetPasswordForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");

  const identifier = location.state?.identifier || "your email or number";
  const otp = location.state?.otp || "your otp";

  if (!identifier || !otp) {
    toast.error("Missing identifier or OTP.");
    navigate("/forgot-password"); // Redirect to forgot password flow
    return;
  }

  const handleOpenDialog = () => {
    setDialogTitle("Password Set Successfully!");
    setDialogContent("Your password has been reset successfully.");
    setButtonLabel("Back to Login");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Navigate to login page when the confirm button is clicked
    navigate("/login");
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    // Get the new password and confirm password values
    const newPassword = document.getElementById("new-password").value.trim();
    const confirmPassword = document.getElementById("confirm-password").value.trim();

    // Validation
    if (!newPassword || !confirmPassword) {
      toast.error("Please fill in all fields.");
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }

    try {
      // Call resetPassword service
      await resetPassword(identifier, otp, newPassword);
      handleOpenDialog(); // Open success dialog on successful password reset
    } catch (error) {
      console.error("Error resetting password:", error);
    }
  };

  return (
    <div
      className="bg-[var(--color-white)] rounded-[20px] padding-responsive"
      style={{ boxShadow: "0px 9px 34px 0px #0000001A" }}
    >
      <form onSubmit={handleResetPassword} className="px-2">
        <div className="flex items-center mt-6">
          <span className="text-[var(--color-dark-grey)] mb-1 font-heading font-semibold large-text mr-2">
            Reset Password
          </span>
          <ResetIcon className="-mt-3 xxs:w-8 xs:w-10" />
        </div>
        <div className="mb-8 text-blue-text small-text">
          Your new password must be different from previously used passwords
        </div>
        <TextInput
          id="new-password"
          type="password"
          label="New Password"
          placeholder="Enter New Password"
          className="mb-8"
        />
        <TextInput
          id="confirm-password"
          type="password"
          label="Confirm New Password"
          placeholder="Confirm New Password"
          className="mb-10"
        />
        <PrimaryButton
          type="submit"
          label="Set New Password"
          className="w-full"
          onClick={handleResetPassword}
        />
        <div className="mt-10 mb-6 text-center medium-text flex items-center justify-center">
          <button
            type="button"
            className="text-blue text-sm flex items-center"
            onClick={() => navigate("/")}
          >
            <ChevronLeftIcon
              className="h-4 w-4 text-blue mr-3"
              aria-hidden="true"
              strokeWidth={3}
            />
            Back to Login
          </button>
        </div>
      </form>

      {/* Pass the dynamic title and content to CommonDialog */}
      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showRightIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default ResetPasswordForm;
